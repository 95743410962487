import { RetailPlaceModel } from 'src/app/models/retailPlace.model';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StocktakingFilter, StocktakingSource, StocktakingModel } from 'src/app/models/documents/stockTaking.model';
import { GroupModel } from 'src/app/models/group.model';
import { WarehouseModel } from 'src/app/models/warehouse.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ArticleModel } from 'src/app/models/article.model';
import { StocktakingArticleModel } from 'src/app/models/documents/stocktakingArticle.model';
import { GroupRestService } from 'src/app/services/group.service';
import { WarehouseRestService } from 'src/app/services/warehouse.service';
import { DatePipe } from '@angular/common';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from 'src/app/translate';
import { StocktakingRestService } from 'src/app/services/stocktaking.service';
import { StocktakingSaveModel } from 'src/app/models/documents/pageSaveModels/stocktakingSave.model';
import { minValidator } from 'src/app/validators/minValidator';
import toastr from 'toastr';
import { ArticleRestService } from 'src/app/services/article.service';
import { StocktakingLogModel } from 'src/app/models/documents/stocktakingLog.mode';
import { UpdateInvoiceDiscountDTO } from 'src/app/models/documents/updateInvoiceDiscountDTO';
import { StocktakingDifferenceArticleModel, StocktakingDifferenceModel } from 'src/app/models/documents/stocktakingDifference.model';
import { Router } from '@angular/router';
import { DocumentPdfComponent } from '../document-pdf/document-pdf.component';

declare var $: any;
declare var Swal: any;
declare var Inputmask: any;
declare var KTMenu: any;
@Component({
    selector: 'app-stocktaking-difference',
    templateUrl: './stocktaking-difference.component.html',
})
export class StocktakingDifferenceComponent implements OnInit {
    public selectedRow: string = undefined;
    public stocktakings: StocktakingDifferenceModel[];
    public groups: GroupModel[];
    public warehouses: WarehouseModel[];
    public retailPlaces: RetailPlaceModel[];
    public emptyData: boolean = false;
    public searchForm: UntypedFormGroup;
    public addForm: UntypedFormGroup;
    public isSubmitted: boolean;
    public selectedStocktaking: StocktakingDifferenceModel;
    public selectedStocktakingWarehouse: WarehouseModel;
    public filterWarehouseId: number;
    public onHeaderTab: boolean = true;

    public activeStocktaking: StocktakingDifferenceModel;
    public totalAmount: number = 0;
    tableSetUp: boolean = false;
    public selectedCurrency: string;
    dateFrom: string;
    dateTo: string;
    selectionModalTimer: any;
    enterCount: number = 0;

    @ViewChild('selectFilterWarehouseId') selectFilterWarehouseId: ElementRef;
    @ViewChild('headerTrigger') headerTrigger: ElementRef;
    @ViewChild('itemsTrigger') itemsTrigger: ElementRef;
    @ViewChild('listTrigger') listTrigger: ElementRef;
    @ViewChild('firstHeaderForm') firstHeaderFormElement: ElementRef;
    @ViewChild('sourcesModalClose') sourcesModalClose: ElementRef;
    @ViewChild('filtersModalClose') filtersModalClose: ElementRef;
    @ViewChild('controlModalClose') controlModalClose: ElementRef;
    @ViewChild('barcodeInput') barcodeInput: ElementRef;
    @ViewChild(DocumentPdfComponent) documentPdfComponent: DocumentPdfComponent;
    
    public showItems: boolean = true;
    public setUpComplete: boolean = false;
    public editEnabled: boolean = false;
    public startedEditing: boolean = false;
    public nextNumber: number;
    public addEnabled: boolean = false;
    public headerSaved: boolean = false;
    public editingItems: boolean = false;
    public editingItem: StocktakingArticleModel;
    public pendingFilterChanges: StocktakingFilter[] = [];
    public fetchInProgress: boolean;
    public preventScrollLoadingitem: boolean = false;
    public itemsTabOpen: boolean;
    public showSpinner = false;
    public barcodeArticles: StocktakingDifferenceArticleModel[] = [];
    public selectedArticle: StocktakingDifferenceArticleModel;
    public pdfSource: any;
    public showPdf:boolean=false;
    public activeStocktakingNumber:string='';

    //pagination
    public page: number = 1;
    public perPage: number;
    public totalData: number;
    public maxPage: number;
    public pages: number[] = [];
    public offset: number = 5;
    public firstPage: number = 1;   
    public lastPage: number;
    public counter: number = 0;
    public firstLoad: boolean = true;
    public resizeObserver: ResizeObserver;

    public articlePage: number = 1;
    public articlePageSize: number = 40;
    public totalArticleData: number;
    public maxArticlePage: number;
    public articlePages: number[] = [];
    public articleOffset: number = 5;
    public firstArticlePage: number = 1;
    public lastArticlePage: number;
    public counterArticlePage: number = 0;
    public firstArticleLoad: boolean = true;
    public showingSources: string[] = [];
    public focusLast: boolean = false;
    public controlUpdate: boolean;
    public enteredBarcode: boolean = false;
    public searchBarcode: string;
    public searchInputQuantity: any;
    public sourcesForTimelineDisplay: StocktakingSource[] = [];
    public logs: StocktakingLogModel[] = [{ articleId: 1, articleName: 'test', attemptedEntry: 10 }];
    public searchInput: boolean = false;
    public sortByGroup: boolean = false;

    @HostListener('window:scroll', ['$event'])
    onScroll(event: Event): void {
        if (!this.itemsTabOpen || this.fetchInProgress || this.preventScrollLoadingitem)
            return;
        let documentHeight = document.body.scrollHeight;
        let currentScroll = window.scrollY + window.innerHeight;
        let modifier = 20;
        if ((currentScroll + modifier) > documentHeight) {
            this.articlePage++;
            this.getArticles();
        }
    }

    constructor(
        private stocktakingRest: StocktakingRestService,
        private groupRestService: GroupRestService,
        private warehouseRestService: WarehouseRestService,
        private articleRestService: ArticleRestService,
        private datePipe: DatePipe,
        private _changeRef: ChangeDetectorRef,
        public data: DataService,
        private formBuilder: UntypedFormBuilder,
        private translate: TranslateService,
        private router: Router
    ) {
        this.page = 1;
        this.perPage = 50;
    }

    ngOnInit(): void {
        KTMenu.createInstances();
        this.fetchStocktaking();
        this.fetchGroups();
        this.fetchWarehouses();
        this.createSearchForm();
        this.viewDocuments();
    }

    ngAfterViewInit() {
        this.showList();
    }

    async createSearchForm() {
        this.searchForm = this.formBuilder.group({
            filterWarehouseId: [null]
        })

        $("#invoice_filter_warehouseId").select2();
        $('#invoice_filter_warehouseId').on("change", function () {
            self.selectFilterWarehouseId.nativeElement.value = $(this).val();
            self.filterWarehouseId = $(this).val();
        });

        var start = new Date(new Date().getFullYear(), 0, 1);
        var end = new Date();
        var self = this;
        $("#kt_daterangepicker_filter").daterangepicker({
            startDate: start,
            endDate: end,
            autoApply: true,
            autoUpdateInput: true,
            locale: {
                format: 'DD/MM/YYYY',
                separator: '-'
            }
        });
        $("#kt_daterangepicker_filter").on('apply.daterangepicker', function (ev, picker) {
            self.dateFrom = picker.startDate.format('YYYY-MM-DD');
            self.dateTo = picker.endDate.format('YYYY-MM-DD');
            self.fetchStocktaking();
        });
        $("#kt_daterangepicker_filter").on('cancel.daterangepicker', function (ev, picker) {
            picker.setStartDate(new Date());
            picker.setEndDate(new Date());
            self.dateFrom = picker.startDate.format('YYYY-MM-DD');
            self.dateTo = picker.endDate.format('YYYY-MM-DD');
            self.fetchStocktaking();
        });
        var im = new Inputmask("99/99/9999-99/99/9999");
        im.mask($("#kt_daterangepicker_filter"));
    }

    async resetFilter() {
        this.searchForm.reset();
        $("#invoice_filter_warehouseId").val(null).trigger("change");
        var pickerElement = $("#kt_daterangepicker_filter");
        var today = new Date();
        $(pickerElement).data('daterangepicker').setStartDate(new Date(today.getFullYear(), 0, 1, 0, 0 ,0));
        $(pickerElement).data('daterangepicker').setEndDate(new Date());
        $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
    }

    filterByDate(event: KeyboardEvent) {
        if (event.key == "Enter" || event.key == "Tab") {
            var pickerElement = $("#kt_daterangepicker_filter");
            $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
        }
    }

    async fetchGroups() {
        try {
            var response = await this.groupRestService.getGroups('', null, this.data.user.clientId, 1, 100000);
            this.groups = response.data;
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchWarehouses() {
        try {
            this.warehouses = await this.warehouseRestService.getWarehousesForClient(this.data.user.clientId);
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchStocktaking() {
        try {
            this.emptyData = false;
            if (this.dateFrom == undefined)
                this.dateFrom = this.datePipe.transform((new Date(new Date().getFullYear(), 0, 1)), 'yyyy-MM-dd');
            if (this.dateTo == undefined)
                this.dateTo = this.datePipe.transform((new Date()), 'yyyy-MM-dd');

            var response = await this.stocktakingRest.getStocktakingDifferences(this.filterWarehouseId, this.dateFrom, this.dateTo, this.data.user.clientId, this.page, this.perPage);
            this.stocktakings = response.data;

            this.totalData = response.totalCount;
            if (this.totalData == 0)
                this.emptyData = true;
            else
                this.emptyData = false

            if (this.firstLoad) {
                this.maxPage = response.totalPages;
                this.maxPage = Math.floor(this.maxPage);
                this.firstPage = 1;
                if (this.maxPage > 5)
                    this.lastPage = 5;
                else
                    this.lastPage = this.maxPage;
                this.enablePrevious();

                this.firstLoad = false;
            }
            this.initPages();
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchInvoicePdf(id: any) {
        try {
            var response = await this.stocktakingRest.getStocktakingDifferencePDF(this.data.user.clientId, id);
            this.pdfSource = window.URL.createObjectURL(response);
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
            else {
                toastr.options = this.data.toastrOptions;
                toastr.warning(this.translate.instant("_No_data"), this.translate.instant("_Warning"));
            }
        }
    }

    async exportPDF() {
        await this.fetchInvoicePdf(this.activeStocktaking.id)
        if (this.pdfSource){
            // this.router.navigate(['/stocktaking-difference-pdf'], { queryParams: { pdfSrc: this.pdfSource } });
            this.showPdf=true;
            this.documentPdfComponent.setSource(this.pdfSource);
        }
        else{
            toastr.options = this.data.toastrOptions;
                toastr.warning(this.translate.instant("_No_data"), this.translate.instant("_Warning"));
        }
    }
    closePDF(){
        this.showPdf=false;
    }

    async searchStocktaking() {
        this.firstLoad = true;
        this.page = 1;
        this.fetchStocktaking();
    }

    setItemTabOpen(val: boolean) {
        this.itemsTabOpen = val;
    }

    viewDocuments() {
        this.setItemTabOpen(false);
        this.toggleDocNav(false);
        this.headerSaved = false;
        this.startedEditing = false;
        this.editEnabled = false;
        this.addEnabled = false;
        this.editingItems = false;
        this.activeStocktaking = null;
    }

    switchToHeader() {
        this.headerTrigger.nativeElement.click();
    }
    switchToArticles() {
        this.itemsTrigger.nativeElement.click();
    }

    async selectStocktaking(stocktaking: StocktakingDifferenceModel, switchToHeaderTab: boolean = true) {
        if (switchToHeaderTab)
            this.headerTrigger.nativeElement.click();
        this.activeStocktaking = stocktaking;
        this.activeStocktakingNumber = this.activeStocktaking.stocktakingDifferenceNumber;
        stocktaking.articles = null;
        this.activeStocktaking.articles = null;
        this.addEnabled = false;
        this.editEnabled = true;
        this.startedEditing = false;

        if (this.searchInput) {
            this.getAllArticles();
        } else {
            this.focusLast = true;
            await this.getArticles();
            this.focusLast = false;
        }

        this.selectedArticle = null;
        this.headerSaved = true;
    }

    jumpToBarcode() {
        window.scrollTo(0, 0);
        this.barcodeInput.nativeElement.focus();
    }

    async selectNext() {
        var index = this.stocktakings.findIndex(x => x.id == this.activeStocktaking.id);

        if (index == 0) {
            if (this.page == 1)
                return;

            await this.prevpage();
            await this.fetchStocktaking()

            if (this.stocktakings.length > 0) {
                this.selectStocktaking(this.stocktakings[this.stocktakings.length - 1]);
            }
        }
        else {
            this.selectStocktaking(this.stocktakings[index - 1]);
        }
    }

    async selectPrevious() {
        var index = this.stocktakings.findIndex(x => x.id == this.activeStocktaking.id);
        if (index == (this.stocktakings.length - 1)) {
            if (this.page == this.maxPage)
                return;

            await this.nextPage();
            await this.fetchStocktaking();

            if (this.stocktakings.length > 0) {
                this.selectStocktaking(this.stocktakings[0]);
            }
        }
        else {
            this.selectStocktaking(this.stocktakings[index + 1]);
        }
    }

    exitDetails(redirectToHeader: boolean = false) {
        if (redirectToHeader)
            this.headerTrigger.nativeElement.click();
        this.headerSaved = false;
    }

    manualInputDate(id: string) {
        var pickerElement = $("#" + id);
        $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
    }

    selectOnFocus(id: string) {
        $("#" + id).select();
    }

    async getArticles() {
        this.fetchInProgress = true;
        try {
            var lastShownSource = 0;
            if (this.sortByGroup) {
                if (this.activeStocktaking.articles != undefined) {
                    var shownSources = this.activeStocktaking.articles.filter(x => x.id == 0);
                    if (shownSources.length > 0) {
                        var sourceName = shownSources[shownSources.length - 1].name;
                        lastShownSource = this.groups.find(x => x.name == sourceName).id;
                    }
                }
            }

            var t = this.activeStocktaking.filters.filter(x => x.checked == true);
            var sourceFilters = [];
            for (let index = 0; index < t.length; index++) {
                const element = t[index].id;
                sourceFilters.push(element);
            }

            var response = await this.stocktakingRest.getStocktakingDifferenceArticles(this.activeStocktaking.id, sourceFilters, this.sortByGroup, lastShownSource, this.articlePage, this.articlePageSize);

            if (this.activeStocktaking.articles == undefined || this.activeStocktaking.articles.length == 0)
                this.activeStocktaking.articles = response.data;
            else {
                var previousLength = this.activeStocktaking.articles.length;
                this.activeStocktaking.articles = this.activeStocktaking.articles.concat(response.data);
                if (this.activeStocktaking.articles.length == previousLength)
                    this.preventScrollLoadingitem = true;
            }
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async getAllArticles() {
        this.fetchInProgress = true;
        try {
            var response = await this.stocktakingRest.getStocktakingDifferenceArticles(this.activeStocktaking.id, [], this.sortByGroup, 0, this.articlePage, 999999);
            this.activeStocktaking.articles = response.data;
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async deleteStocktaking(id: string) {
        var self = this;
        var question_text = this.translate.instant("_Delete_stocktaking_text");
        var yes_text = this.translate.instant("_Yes_text");
        var no_text = this.translate.instant("_No_text");
        Swal.fire({
            html: question_text,
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-secondary'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.stocktakingRest.deleteStocktaking(id);
                if (this.activeStocktaking.id == id) {
                    this.startedEditing = false;
                    this.editEnabled = true;
                    this.addEnabled = false;
                    this.editingItems = false;
                }
                await this.fetchStocktaking();
                toastr.options = this.data.toastrOptions;
                toastr.success(this.translate.instant('_Deleted_stocktaking_text'), this.translate.instant("_Success"));
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }

    backToHeader() {
        $("#headerModalTrigger").click();
    }

    negateEnter(event: KeyboardEvent) {
        if (event.key == "Enter")
            event.preventDefault();
    }

    validateItemForm() {
        var check = true;

        if (this.selectedArticle == undefined) {
            check = false;
            $("#itemCodeInput").addClass('is-invalid');
        }
        else {
            $("#itemCodeInput").removeClass('is-invalid');
        }

        return check;
    }

    closeSourcesModal() {
        this.sourcesModalClose.nativeElement.click();
    }

    checkFilter(id: number) {
        var existing = this.pendingFilterChanges.find(x => x.id == id);
        if (existing != undefined) {
            this.pendingFilterChanges.splice(this.pendingFilterChanges.indexOf(existing), 1);
            return;
        }

        var change = new StocktakingFilter();
        var filter = this.activeStocktaking.filters.find(x => x.id == id);
        change.id = filter.id;
        change.name = filter.name;
        change.checked = !filter.checked;

        this.pendingFilterChanges.push(change);
    }

    async updateFilters() {
        this.isSubmitted = false;
        try {
            this.pendingFilterChanges.forEach(update => {
                this.activeStocktaking.filters.find(x => x.id == update.id).checked = update.checked;
            });
            this.filtersModalClose.nativeElement.click();
            this.pendingFilterChanges = [];
            this.activeStocktaking.articles = undefined;
            this.focusLast = true;
            await this.getArticles();
            this.focusLast = false;
        }
        catch (err: any) {
            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
    }

    toggleDocNav(toggle: boolean) {
        this.onHeaderTab = toggle;
    }

    toggleUIControl() {
        this.searchInput = !this.searchInput;
    }

    toggleSortByGroup() {
        this.sortByGroup = !this.sortByGroup;
    }

    closeFiltersModal() {
        this.filtersModalClose.nativeElement.click();
    }

    showList() {
        this.listTrigger.nativeElement.click();
    }

    async updateControl() {
        if (this.searchInput == this.controlUpdate)
            return;
        try {
            this.fetchStocktaking();
            this.activeStocktaking.articles = [];
            this.articlePage = 1;

            if (this.controlUpdate)
                this.getAllArticles();
            else
                this.getArticles();

            this.closeControlModal();
        }
        catch (err) {
            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
    }

    closeControlModal() {
        this.controlUpdate = this.searchInput;
        this.controlModalClose.nativeElement.click();
    }

    // async enterBarcode(){
    //   if(this.searchBarcode!="" && this.searchBarcode!=null && this.searchBarcode!=undefined){
    //       try{
    //           var article = this.activeStocktaking.articles.filter(x=>x.barcodes!=null).find(x=>x.barcodes.includes('"'+ this.searchBarcode+ '"'));
    //           if(article==undefined){
    //               article = await this.stocktakingRest.getStocktakingArticleByBarcode(this.activeStocktaking.id, this.searchBarcode, this.activeStocktaking.clientId);
    //           }
    //           else {
    //               if(!this.barcodeArticles.includes(article))
    //                   this.barcodeArticles.push(article); 
    //               this.lastId=article.id;
    //               this._changeRef.detectChanges();
    //               this.jumpToLast();
    //           }
    //       }
    //       catch(err:any){
    //           toastr.options = this.data.toastrOptions;
    //           toastr.error(this.translate.instant(err.error['Message']), this.translate.instant("_Error"));
    //       }
    //   }
    // }

    prepareSourcesForTimelineDisplay() {
        var checkedSources = this.activeStocktaking.sources.filter(x => x.checked);
        if (this.sourcesForTimelineDisplay.length != checkedSources.length) {
            this.sourcesForTimelineDisplay = checkedSources;
            this._changeRef.detectChanges();
        }
    }

    //pagination
    initPages() {
        this.pages = [];
        for (var i = this.firstPage; i <= this.lastPage; i++) {
            this.pages.push(i);
        }
    }

    nextPage() {
        if ($("#kt_customers_table_next").hasClass("disabled"))
            return;

        if (this.page == 1 && this.firstPage == 1) {
            this.page++;
        }

        else {
            this.page++;
            if (this.counter == 1) {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage) {
                    if (this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else
                        this.firstPage = 1;
                    this.lastPage = this.maxPage;
                }
                this.counter = 0;
            }
            else {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else {
                    if (this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else
                        this.firstPage = 1;
                    this.lastPage = this.maxPage;
                }
            }
            this.counter++;
        }
        this.fetchStocktaking();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    prevpage() {
        if ($("#kt_customers_table_previous").hasClass("disabled"))
            return;

        this.page--;
        if (this.counter == 1) {
            if (this.maxPage > this.lastPage + this.offset) {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
            this.counter = 0;
        }
        else {
            if (this.maxPage > this.lastPage + this.offset) {
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if (this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage = this.maxPage;
                this.initPages();
            }
        }
        this.counter++;
        this.fetchStocktaking();
        this.enablePrevious();
        this._changeRef.detectChanges();

    }

    goToPage(p: number) {
        this.page = p;
        this.fetchStocktaking();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    morePages() {
        if ($("#kt_customers_table_morePages").hasClass("disabled"))
            return;
        if (this.maxPage > this.lastPage + this.offset) {
            this.firstPage = this.lastPage;
            this.lastPage = this.firstPage + this.offset;
        }
        else {
            this.firstPage = this.maxPage - this.offset;
            this.lastPage = this.maxPage;
        }
        this.page = this.firstPage;
        this.fetchStocktaking();
        this.initPages();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }


    perPageHandler() {
        this.firstLoad = true;
        this.page = 1;
        this.fetchStocktaking();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    enablePrevious() {
        if (this.page > 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").removeClass("disabled");
            })
        }
        else if (this.page == 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page > 1 && this.page != this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        if (this.maxPage == 0) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
                $("#morePages").addClass("disabled");
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        if (this.maxPage <= 5 || this.lastPage == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").removeClass("disabled");
            })
        }
    }
}
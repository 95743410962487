<div class="toolbar" id="kt_toolbar">
    <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
        <div data-kt-swapper="true" data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
            <h1 class="d-flex align-items-center text-dark fw-bolder fs-3 my-1">{{'_Settings' | translate}}</h1>
            <span class="h-20px border-gray-300 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                    <a [routerLink]="['/home']" class="text-muted text-hover-primary">{{'_Dashboard' |
                        translate}}</a>
                </li>
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-300 w-5px h-2px"></span>
                </li>
                <li class="breadcrumb-item text-dark">{{'_Settings' | translate}}</li>
            </ul>
        </div>
    </div>
</div>
<div class="post d-flex flex-column-fluid" id="kt_post">
    <div id="kt_content_container" class="container-xxl">
        <form id="kt_account_profile_details_form" class="form fv-plugins-bootstrap5 fv-plugins-framework"
                    novalidate="novalidate" [formGroup]="formGroup">
            <div class="card mb-5 mb-xl-10">
                <div id="kt_account_settings_article_details" class="collapse show">
                        <div class="card-body border-top p-9">
                            <h3 class="mb-5">{{'_General_settings' | translate}}</h3>
                            <div class="row mb-6">
                                <div class="form-check form-check-custom form-check-solid form-check-sm">
                                    <label class="form-check-label fs-6  mx-4">{{'_Skip_after_MPC'|translate}}</label>
                                    <input class="form-check-input" tabindex="11" type="checkbox"  name="skipAfterMPC" value="" formControlName="skipAfterMPC" />
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex justify-content-end py-6 px-9">
                            <button type="button" class="btn btn-light btn-active-light-primary me-2" (click)="discardChangesGeneralSettings()">{{'_Discard' | translate}}</button>
                            <button type="button" class="btn btn-primary" id="kt_account_profile_details_submit" (click)="updateGeneralSettings()">{{'_Submit' | translate}}</button>
                        </div>
                        <input type="hidden">
                        <div></div>
                </div>
            </div>
            <div class="card mb-5 mb-xl-10">
                <div id="kt_account_settings_profile_details" class="collapse show">
                        <div class="card-body border-top p-9">
                            <h3 class="mb-5">{{'_Fiscalization' | translate}}</h3>
                            <div class="row mb-6">
                                <label class="col-lg-2 col-form-label required fw-bold fs-6">{{'_Fiscal_type' |
                                    translate}}</label>
                                <div class="col-lg-4 fv-row fv-plugins-icon-container">
                                    <select #fiscalTypeSelect class="form-select form-select-solid fw-bolder"
                                        data-placeholder="Odaberite tip fiskalizacije" id="fiscalTypeSelect"
                                        data-dropdown-parent="#kt_account_profile_details_form"
                                        formControlName="fiscalTypeId" [(ngModel)]="fiscalTypeId" name="selectFiscalType">
                                        <option></option>
                                        <option *ngFor="let f of fiscalTypes" [value]="f.id">{{f.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-6" *ngIf="data.fiscalTypeCode!='TRI' && fiscalTypeCode!='TRI'">
                                <label class="col-lg-2 col-form-label required fw-bold fs-6">{{'_Operator_name' |
                                    translate}}</label>
                                <div class="col-lg-4 fv-row fv-plugins-icon-container">
                                    <input type="text" name="fname"
                                        class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" formControlName="operatorName" required
                                        [ngClass]="{ 'is-invalid': isSubmitted && f.operatorName.errors }">
                                    <div class="fv-plugins-message-container invalid-feedback"></div>
                                </div>
                            </div>
                            <div class="row mb-6" *ngIf="data.fiscalTypeCode!='TRI' && fiscalTypeCode!='TRI'">
                                <label class="col-lg-2 col-form-label required fw-bold fs-6">{{'_Operator_code' |
                                    translate}}</label>
                                <div class="col-lg-4 fv-row fv-plugins-icon-container">
                                    <input type="text" name="fname"
                                        class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" formControlName="operatorCode" required
                                        [ngClass]="{ 'is-invalid': isSubmitted && f.operatorCode.errors }">
                                    <div class="fv-plugins-message-container invalid-feedback"></div>
                                </div>
                            </div>
                            <div class="row mb-6" *ngIf="data.fiscalTypeCode!='TRI' && fiscalTypeCode!='TRI'">
                                <label class="col-lg-2 col-form-label required fw-bold fs-6">{{'_Fiscal_device_id' |
                                    translate}}</label>
                                <div class="col-lg-4 fv-row fv-plugins-icon-container">
                                    <input type="text" name="fname"
                                        class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" formControlName="fiscalDevice" required
                                        [ngClass]="{ 'is-invalid': isSubmitted && f.fiscalDevice.errors }">
                                    <div class="fv-plugins-message-container invalid-feedback"></div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex justify-content-end py-6 px-9">
                            <button type="button" class="btn btn-light btn-active-light-primary me-2" (click)="discardChanges()">{{'_Discard' | translate}}</button>
                            <button type="submit" class="btn btn-primary" id="kt_account_profile_details_submit" (click)="updateConfiguration()">{{'_Submit' | translate}}</button>
                        </div>
                        <input type="hidden">
                        <div></div>
                </div>
            </div>
        </form>
    </div>
</div>

<ng-http-loader [spinner]="'sk-wave'"></ng-http-loader>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-document-pdf',
  templateUrl: './document-pdf.component.html',
})
export class DocumentPdfComponent {
  pdfSrc: any;
  sanitizedPdfSrc: SafeResourceUrl;
  @Input() fileName: string;
  @Input() documentNumber: string;
  @Input() useUID:boolean=false;
  @Output() backEvent = new EventEmitter();


  constructor(private route: ActivatedRoute,
      private router: Router,
      private sanitizer: DomSanitizer) { }

  public setSource(source:any){
    this.pdfSrc=source;
    this.sanitizedPdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfSrc);
  }

  back() {
    this.backEvent.emit();
  }

  saveFile() {
      var a = document.createElement('a');
      a.href = this.pdfSrc;
      if(this.useUID){
        var uid = uuidv4();
        a.download = this.fileName + '_' + uid;
      }
      else
        a.download = this.fileName + '_' + this.documentNumber;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(this.pdfSrc);
    }

    printPDF() {
      const iframe = document.getElementById('pdfIframeII') as HTMLIFrameElement;
      setTimeout(() => {
          const iframeWindow = iframe.contentWindow;
          if (iframeWindow) {
              iframeWindow.focus();
              iframeWindow.print();
          }
      }, 1000);
    }
}

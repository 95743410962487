import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomerSuppliersModel } from 'src/app/models/customerSupplier.model';
import { RetailBillModel } from 'src/app/models/documents/retailBill.model';
import { PaymentTypeModel } from 'src/app/models/paymentType.model';
import { PointOfSaleModel } from 'src/app/models/pointOfSale.model';
import { RetailPlaceModel } from 'src/app/models/retailPlace.model';
import { WarehouseModel } from 'src/app/models/warehouse.model';
import { CustomerSupplierRestService } from 'src/app/services/customerSupplier.service';
import { DataService } from 'src/app/services/data.service';
import { PaymentTypeRestService } from 'src/app/services/payment-type.service';
import { PointOfSaleRestService } from 'src/app/services/pointOfSale.service';
import { RetailBillRestService } from 'src/app/services/retail-bill.service';
import { RetailPlaceRestService } from 'src/app/services/retailPlace.service';
import { WarehouseRestService } from 'src/app/services/warehouse.service';
import { TranslateService } from 'src/app/translate';

declare var $: any;
declare var KTMenu: any;
declare var Inputmask: any;

@Component({
    selector: 'app-retail-bill',
    templateUrl: './retail-bill.component.html',
})
export class RetailBillComponent implements OnInit {
    public retailBills: RetailBillModel[];
    public warehouses: WarehouseModel[];
    public paymentTypes: PaymentTypeModel[];
    public retailPlaces: RetailPlaceModel[];
    public pointsOfSale: PointOfSaleModel[];
    public customers: CustomerSuppliersModel[];
    public emptyData: boolean = true;
    public searchForm: UntypedFormGroup;
    public name: string;
    public fromDate: string;
    public toDate: string;
    public paymentTypeId: number;
    public pointOfSaleId: number;
    public warehouseId: number;
    public customerId: number;
    public selectedBill: RetailBillModel;
    public selectedWarehouse: WarehouseModel;
    public selectedPaymentType: PaymentTypeModel;
    public selectedRetailPlace: RetailPlaceModel;
    public selectedPointOfSale: PointOfSaleModel;

    @ViewChild('selectFilterWarehouseId') selectFilterWarehouseId: ElementRef;
    @ViewChild('selectFilterPaymentTypeId') selectFilterPaymentTypeId: ElementRef;
    @ViewChild('selectFilterPointOfSaleId') selectFilterPointOfSaleId: ElementRef;
    @ViewChild('selectFilterCustomerId') selectFilterCustomerId: ElementRef;

    //pagination
    public page: number = 1;
    public perPage: number;
    public totalData: number;
    public maxPage: number;
    public pages: number[] = [];
    public offset: number = 5;
    public firstPage: number = 1;
    public lastPage: number;
    public counter: number = 0;
    public firstLoad: boolean = true;

    constructor(
        private retailBillRest: RetailBillRestService,
        private warehouseRest: WarehouseRestService,
        private paymentRest: PaymentTypeRestService,
        private retailPlaceRest: RetailPlaceRestService,
        private pointOfSaleRest: PointOfSaleRestService,
        private customerRest: CustomerSupplierRestService,
        private formBuilder: UntypedFormBuilder,
        public data: DataService,
        private _changeRef: ChangeDetectorRef,
        private translate: TranslateService,
        private router: Router,
        private datePipe: DatePipe,
    ) {
        this.page = 1;
        this.perPage = 50;
    }

    ngOnInit(): void {
        KTMenu.createInstances();
        this.getRetailBills();
        this.fetchWarehouses();
        this.fetchPaymentTypes();
        this.fetchPointsOfSale();
        this.fetchRetailPlaces();
        this.fetchCustomers();
        this.createSearchForm();
    }

    async getRetailBills() {
        try {
            this.emptyData = false;
            if (this.fromDate == undefined)
                this.fromDate = this.datePipe.transform((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0)), 'yyyy-MM-dd HH:mm');
            if (this.toDate == undefined)
                this.toDate = this.datePipe.transform((new Date()), 'yyyy-MM-dd HH:mm');
            var response = await this.retailBillRest.getRetailBills(this.name, this.fromDate, this.toDate, this.warehouseId, this.paymentTypeId, this.pointOfSaleId, this.customerId, this.data.user.clientId, this.page, this.perPage);
            this.retailBills = response.data;

            this.totalData = response.totalCount;
            if (this.totalData == 0)
                this.emptyData = true;
            else
                this.emptyData = false

            if (this.firstLoad) {
                this.maxPage = response.totalPages;
                this.maxPage = Math.floor(this.maxPage);
                this.firstPage = 1;
                if (this.maxPage > 5)
                    this.lastPage = 5;
                else
                    this.lastPage = this.maxPage;
                this.enablePrevious();

                this.firstLoad = false;
            }
            this.initPages();
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchWarehouses() {
        try {
            var response = await this.warehouseRest.filterWarehouses(this.data.user.clientId, "", 1, 10000);
            this.warehouses = response.data;

            if (this.warehouses.length > 0)
                $("#filter_warehouseId").val(this.warehouses[0].id).trigger("change");
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchPaymentTypes() {
        try {
            var response = await this.paymentRest.getPaymentTypes(this.data.user.clientId, '', 1, 99999);
            this.paymentTypes = response.data;
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchRetailPlaces() {
        try {
            var response = await this.retailPlaceRest.getRetailPlaces('', this.data.user.clientId, 1, 100000);
            this.retailPlaces = response.data;
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchPointsOfSale() {
        try {
            var response = await this.pointOfSaleRest.getPointsOfSale(this.data.user.clientId, 1, 100000);
            this.pointsOfSale = response.data;
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchCustomers() {
        try {
            var response = await this.customerRest.getCustomerSuppliers(this.data.user.clientId, undefined, null, null, true, null, null, 1, 10000);
            this.customers = response.data;
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    search() {
        this.firstLoad = true;
        this.page = 1;
        this.getRetailBills();
    }

    async createSearchForm() {
        this.searchForm = this.formBuilder.group({
            name: [''],
            warehouseId: [''],
            paymentTypeId: [''],
            pointOfSaleId: [''],
            customerId: ['']
        });

        $("#filter_warehouseId").select2();
        $('#filter_warehouseId').on("change", function () {
            self.selectFilterWarehouseId.nativeElement.value = $(this).val();
            self.warehouseId = $(this).val();
        });

        $("#filter_paymentTypeId").select2();
        $('#filter_paymentTypeId').on("change", function () {
            self.selectFilterPaymentTypeId.nativeElement.value = $(this).val();
            self.paymentTypeId = $(this).val();
        });

        $("#filter_pointOfSaleId").select2();
        $('#filter_pointOfSaleId').on("change", function () {
            self.selectFilterPointOfSaleId.nativeElement.value = $(this).val();
            self.pointOfSaleId = $(this).val();
            self.search();
        });

        $("#filter_customerId").select2();
        $('#filter_customerId').on("change", function () {
            self.selectFilterCustomerId.nativeElement.value = $(this).val();
            self.customerId = $(this).val();
        });

        var start = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0);
        var end = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59);
        var self = this;
        $("#kt_daterangepicker_filter").daterangepicker({
            startDate: start,
            endDate: end,
            autoApply: true,
            timePicker: true,
            timePicker24Hour: true,
            locale: {
                format: 'DD/MM/YYYY HH:mm',
                separator: '-'
            }
        });
        $("#kt_daterangepicker_filter").on('apply.daterangepicker', function (ev, picker) {
            self.fromDate = picker.startDate.format('YYYY-MM-DD HH:mm');
            self.toDate = picker.endDate.format('YYYY-MM-DD HH:mm');
            $("#searchByCustomerInput").focus();
            self.getRetailBills();
        });
        $("#kt_daterangepicker_filter").on('cancel.daterangepicker', function (ev, picker) {
            var today = new Date();
            picker.setStartDate(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0,0));
            picker.setEndDate(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59));
            self.fromDate = picker.startDate.format('YYYY-MM-DD HH:mm');
            self.toDate = picker.endDate.format('YYYY-MM-DD HH:mm');
            self.getRetailBills();
        });
        var im = new Inputmask("99/99/9999 99:99 - 99/99/9999 99:99");
        im.mask($("#kt_daterangepicker_filter"));
    }

    selectBill(bill: RetailBillModel) {
        this.selectedWarehouse = this.warehouses.find(x => x.id == bill.warehouseId);
        this.selectedPaymentType = this.paymentTypes.find(x => x.id == bill.paymentTypeId);
        this.selectedRetailPlace = this.retailPlaces.find(x => x.id == bill.retailPlaceId);
        this.selectedPointOfSale = this.pointsOfSale.find(x => x.id == bill.pointOfSaleId);
        this.selectedBill = bill;
    }

    exitDetails() {
        this.selectedBill = undefined;
    }

    filterByDate(event: KeyboardEvent) {
        if (event.key == "Enter" || event.key == "Tab") {
            var pickerElement = $("#kt_daterangepicker_filter");
            $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
        }
    }

    resetFilter() {
        this.searchForm.controls['name'].setValue('');
        this.searchForm.controls['warehouseId'].setValue('null');
        $('#filter_warehouseId').val(null).trigger("change");
        var pickerElement = $("#kt_daterangepicker_filter");
        var today = new Date();
        $(pickerElement).data('daterangepicker').setStartDate(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0 ,0));
        $(pickerElement).data('daterangepicker').setEndDate(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59));
        $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
        $('#filter_pointOfSaleId').val(null).trigger("change");
    }

    //pagination
    initPages() {
        this.pages = [];
        for (var i = this.firstPage; i <= this.lastPage; i++) {
            this.pages.push(i);
        }
    }

    nextPage() {
        if ($("#kt_customers_table_next").hasClass("disabled"))
            return;

        if (this.page == 1 && this.firstPage == 1) {
            this.page++;
        }

        else {
            this.page++;
            if (this.counter == 1) {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage) {
                    this.firstPage = this.maxPage - this.offset;
                    this.lastPage = this.maxPage;
                }
                this.counter = 0;
            }
            else {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else {
                    this.firstPage = this.maxPage - this.offset;
                    this.lastPage = this.maxPage;
                }
            }
            this.counter++;
        }
        this.getRetailBills();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    prevpage() {
        if ($("#kt_customers_table_previous").hasClass("disabled"))
            return;

        this.page--;
        if (this.counter == 1) {
            if (this.maxPage > this.lastPage + this.offset) {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }
            this.counter = 0;
        }
        else {
            if (this.maxPage > this.lastPage + this.offset) {
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }
        }
        this.counter++;
        this.getRetailBills();
        this.enablePrevious();
        this._changeRef.detectChanges();

    }

    goToPage(p: number) {
        this.page = p;
        this.getRetailBills();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    morePages() {
        if ($("#kt_customers_table_morePages").hasClass("disabled"))
            return;
        if (this.maxPage > this.lastPage + this.offset) {
            this.firstPage = this.lastPage;
            this.lastPage = this.firstPage + this.offset;
        }
        else {
            this.firstPage = this.maxPage - this.offset;
            this.lastPage = this.maxPage;
        }
        this.page = this.firstPage;
        this.getRetailBills();
        this.initPages();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    perPageHandler() {
        this.firstLoad = true;
        this.page = 1;
        this.getRetailBills();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    enablePrevious() {
        if (this.page > 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").removeClass("disabled");
            })
        }
        else if (this.page == 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page > 1 && this.page != this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        if (this.maxPage == 0) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
                $("#morePages").addClass("disabled");
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        if (this.maxPage <= 5 || this.lastPage == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").removeClass("disabled");
            })
        }
    }
}
<div id="kt_header" class="header align-items-stretch" tabindex="-1">
    <div class="container-fluid d-flex align-items-stretch justify-content-between">
      <div class="d-flex align-items-center d-lg-none ms-n2 me-2" title="Show aside menu">
        <div class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px" id="kt_aside_mobile_toggle">
          <span class="svg-icon svg-icon-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                fill="black" />
              <path opacity="0.3"
                d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                fill="black" />
            </svg>
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
        <a [routerLink]="['/home']" class="d-lg-none">
          <img alt="Logo" src="assets/media/logos/logo-2.svg" class="h-30px" />
        </a> 
      </div>
      <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
        <div class="d-flex align-items-stretch" id="kt_header_nav">
          <div class="header-menu align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="header-menu"
            data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true"
            data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="end"
            data-kt-drawer-toggle="#kt_header_menu_mobile_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">
            <div
              class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
              id="#kt_header_menu" data-kt-menu="true">
              <div [routerLink]="['/home']" class="menu-item menu-lg-down-accordion me-lg-1">
                <span class="menu-link py-3">
                  <span class="menu-title">{{'_Dashboard' | translate}}</span>
                  <span class="menu-arrow d-lg-none"></span>
                </span>
              </div>
              <div [routerLink]="['/warehouse-state']" class="menu-item menu-lg-down-accordion me-lg-1">
                <span class="menu-link py-3">
                  <span class="menu-title">{{'_Warehouse_state' | translate}}</span>
                  <span class="menu-arrow d-lg-none"></span>
                </span>
              </div>
              <div [routerLink]="['/retail-bill']" class="menu-item menu-lg-down-accordion me-lg-1">
                <span class="menu-link py-3">
                  <span class="menu-title">{{'_Retail_bills' | translate}}</span>
                  <span class="menu-arrow d-lg-none"></span>
                </span>
              </div>
              <div [routerLink]="['/production-document']" class="menu-item menu-lg-down-accordion me-lg-1">
                <span class="menu-link py-3">
                  <span class="menu-title">{{'_Production_documents' | translate}}</span>
                  <span class="menu-arrow d-lg-none"></span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-stretch flex-shrink-0">
          <div class="d-flex align-items-stretch ms-1 ms-lg-3">
          </div>
          <div class="d-flex align-items-center ms-1 ms-lg-3">
            <a class="btn btn-icon btn-icon-muted btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px" [routerLink]="['/fiscalization-settings']">
              <i class="bi bi-gear-fill fs-2 pt-1 text-gray-800"></i>
            </a>
          </div>
          <div class="d-flex align-items-center ms-1 ms-lg-3">
            <a class="btn btn-icon btn-icon-muted btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px" (click)="changeMode()">
              <i class="fonticon-sun fs-2 text-gray-800"></i>
            </a>
          </div>
          <div class="d-flex align-items-center ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
            <div class="cursor-pointer symbol symbol-30px symbol-md-40px" data-kt-menu-trigger="click"
              data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
              <ngx-avatars name="{{data?.user?.firstName + ' '+data?.user?.lastName}}" [round]="false" [cornerRadius]="5"
                [size]="40" [bgColor]="'#3699FF'"></ngx-avatars>
            </div>
            <div
              class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
              data-kt-menu="true">
              <div class="menu-item px-3">
                <div class="menu-content d-flex align-items-center px-3">
                  <div class="symbol symbol-50px me-5">
                    <!-- <ngx-avatars name="{{data?.user?.firstName + ' '+data?.user?.lastName}}" [round]="false"
                      [cornerRadius]="5" [size]="40" [bgColor]="'#3699FF'"></ngx-avatars> -->
                  </div>
                  <div class="d-flex flex-column">
                    <div class="fw-bolder d-flex align-items-center fs-5">{{data?.user?.firstName + '
                      '+data?.user?.lastName}}
                      <span
                        class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">{{data?.user?.clientUserRoleName}}</span>
                    </div>
                    <a [routerLink]="['/profile']"
                      class="fw-bold text-muted text-hover-primary fs-7">{{data?.user?.email}}</a>
                  </div>
                </div>
              </div>
              <div class="separator my-2" *ngIf="data?.user?.clientUserRoleCode=='CO'"></div>
              <div *ngIf="data?.user?.clientUserRoleCode=='CO'" class="menu-item px-5">
                <a [routerLink]="['/profile']" class="menu-link px-5">{{'My profile' | translate}}</a>
              </div>
              <div class="separator my-2"></div>
              <div class="menu-item px-5" data-kt-menu-trigger="hover" data-kt-menu-placement="left-start">
                <a href="#" class="menu-link px-5">
                  <span class="menu-title position-relative">{{'Language' | translate}}
                    <span class="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0"
                      *ngIf="data?.lang=='en'">{{'_English' | translate}}
                      <img class="w-15px h-15px rounded-1 ms-2" src="assets/media/flags/united-kingdom.svg"
                        alt="" /></span>
                  <span class="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0"
                    *ngIf="data?.lang=='bs'">{{'_Bosnia_and_Herzegovine' | translate}}
                    <img class="w-15px h-15px rounded-1 ms-2" src="assets/media/flags/bosnia-and-herzegovina.svg"
                      alt="" /></span></span>
                </a>
                <div class="menu-sub menu-sub-dropdown w-175px py-4">
                  <div class="menu-item px-3">
                    <a (click)="changeLanguage('en')" class="menu-link d-flex px-5" [class.active] = "data?.lang=='en'" >
                      <span class="symbol symbol-20px me-4">
                        <img class="rounded-1" src="assets/media/flags/united-kingdom.svg" alt="" />
                      </span>{{'_English' | translate}}</a>
                  </div>
                  <div class="menu-item px-3">
                    <a (click)="changeLanguage('bs')" class="menu-link d-flex px-5" [class.active] = "data?.lang=='bs'">
                      <span class="symbol symbol-20px me-4">
                        <img class="rounded-1" src="assets/media/flags/bosnia-and-herzegovina.svg" alt="" />
                      </span>{{'_Bosnia_and_Herzegovine' | translate}}</a>
                  </div>
                </div>
              </div>
              <div class="menu-item px-5">
                <a (click)="data?.logout()" class="menu-link px-5">{{'_Sign_out' | translate}}</a>
              </div>
              <div class="separator my-2"></div>
              <div class="menu-item px-5">
                <div class="menu-content px-5">
                  <label class="form-check form-switch form-check-custom form-check-solid pulse pulse-success">
                    <input class="form-check-input w-30px h-20px" type="checkbox" checked (click)="changeMode()"
                      *ngIf="data?.currentMode=='dark'" value="0" name="mode" />
                    <input class="form-check-input w-30px h-20px" type="checkbox" *ngIf="data?.currentMode!='dark'" (click)="changeMode()"
                      value="1" name="mode" />
                    <span class="pulse-ring ms-n1"></span>
                    <span class="form-check-label text-gray-600 fs-7">Dark Mode</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ChangeUserPasswordModel } from 'src/app/models/changeUserPassword.model';
import { ChangeUserPINModel } from 'src/app/models/changeUserPIN.model';
import { ClientModel } from 'src/app/models/client.model';
import { ClientUserRoles } from 'src/app/models/clientUserRoles.model';
import { UserAccountModel } from 'src/app/models/userAccount.model';
import { ClientRestService } from 'src/app/services/client.service';
import { DataService } from 'src/app/services/data.service';
import { UserAccountRestService } from 'src/app/services/userAccount.service';
import { TranslateService } from 'src/app/translate';
import { environment } from '../../../environments/environment';
import toastr from 'toastr';

declare var $: any;
declare var Swal: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit {

  public formGroup: UntypedFormGroup;
  public clientForm: FormGroup;
  public resetformGroup: UntypedFormGroup;
  public resetformGroupPin: UntypedFormGroup;
  public isSubmitted: boolean = false;
  public isSubmittedPass: boolean = false;
  public isSubmittedPin: boolean = false;
  public changePassword: boolean = false;
  public changePin: boolean = false;
  public imagesUrl: string = environment.imagesUrl;
  public memoUrl: string;
  public loginUrl: string;
  memorandumImage: File | null = null;
  public clientData: ClientModel;
  public isSubmittedClient: boolean = false;
  loginImage: File | null = null;
  @ViewChild('loginUpload') loginUpload: ElementRef;
  @ViewChild('memoUpload') memoUpload: ElementRef;

  get f() { return this.formGroup.controls; }
  get f1() { return this.clientForm.controls; }
  get f2() { return this.resetformGroup.controls; }
  get f3() { return this.resetformGroupPin.controls; }

  constructor(
    public data: DataService,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private userAccountRest: UserAccountRestService,
    private _clientRest: ClientRestService
  ) { }

  ngOnInit() {
    this.createForm();
    this.fetchImages();
    this.fetchClientData();
  }

  async fetchClientData() {
    try {
      this.clientData = await this._clientRest.getClientData(this.data.user.clientId);
      this.clientForm.controls['clientName'].setValue(this.clientData.companyName);
      this.clientForm.controls['email'].setValue(this.clientData.email);
      this.clientForm.controls['address'].setValue(this.clientData.address);
      this.clientForm.controls['pdvNumber'].setValue(this.clientData.pdvNumber);
      this.clientForm.controls['idNumber'].setValue(this.clientData.idNumber);
      this.clientForm.controls['phone'].setValue(this.clientData.phone);
      this.clientForm.controls['webAddress'].setValue(this.clientData.webAddress);
      const accounts = this.clientData.bankAccountData;
      accounts.forEach((account) => {
        this.bankAccounts.push(this.createBankAccount(account.bankName, account.bankAccountNumber));
      });
      const footer = this.clientData.footerData;
      footer.forEach((footer) => {
        this.footerData.push(this.createFooterData(footer.header, footer.description));
      });
    }
    catch (err: any) {
      if (err.status == 401)
        this.data.logout();
    }
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
    })

    this.clientForm = this.formBuilder.group({
      clientName: ['', Validators.required],
      email: ['', Validators.required],
      address: [''],
      pdvNumber: [''],
      idNumber: [''],
      phone: [''],
      webAddress: [''],
      bankAcc: [''],
      bankAccounts: this.formBuilder.array([]),
      footerData: this.formBuilder.array([])
    });
    this.setUserValues();
  }

  get bankAccounts(): FormArray {
    return this.clientForm.get('bankAccounts') as FormArray;
  }

  get footerData(): FormArray {
    return this.clientForm.get('footerData') as FormArray;
  }

  createBankAccount(bankName: string = '', accountNumber: string = ''): FormGroup {
    return this.formBuilder.group({
      bankName: [bankName, Validators.required],
      accountNumber: [accountNumber, Validators.required],
    });
  }

  createFooterData(header: string = '', description: string = ''): FormGroup {
    return this.formBuilder.group({
      header: [header, Validators.required],
      description: [description, Validators.required],
    });
  }

  addBankAccount(): void {
    this.bankAccounts.push(this.createBankAccount());
  }

  removeBankAccount(index: number): void {
    this.bankAccounts.removeAt(index);
  }

  addFooterItem() {
    if (this.footerData.length < 3) {
      this.footerData.push(this.createFooterData());
    }
  }

  removeFooterItem(index: number) {
    this.footerData.removeAt(index);
  }

  createResetPasswordForm() {
    this.resetformGroup = this.formBuilder.group({
      currentpassword: ['', Validators.required],
      newpassword: ['', Validators.required],
      confirmpassword: ['', Validators.required]
    });
  }

  createResetPinForm() {
    if (this.data.user.pin != undefined) {
      this.resetformGroupPin = this.formBuilder.group({
        currentPin: [null, Validators.required],
        newPin: ['', Validators.required],
        confirmPin: ['', Validators.required]
      });
    }
    else {
      this.resetformGroupPin = this.formBuilder.group({
        currentPin: [''],
        newPin: ['', Validators.required],
        confirmPin: ['', Validators.required]
      });
    }
  }

  setUserValues() {
    this.formGroup.controls['firstName'].setValue(this.data.user.firstName);
    this.formGroup.controls['lastName'].setValue(this.data.user.lastName);
  }

  setClientValues() {

    /*  */
  }

  discardChanges() {
    var self = this;
    var question_text = this.translate.instant("_Discard_changes_question_text");
    var yes_text = this.translate.instant("_Yes_discard_text");
    var no_text = this.translate.instant("_No_return_text");
    Swal.fire({
      html: question_text,
      icon: "warning",
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: yes_text,
      cancelButtonText: no_text,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: 'btn btn-secondary'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.isSubmitted = false;
        self.setUserValues();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  async updateClient() {
    this.isSubmitted = false;
    if (this.formGroup.invalid) {
      this.isSubmitted = true;
    }

    if (this.isSubmitted)
      return;

    var obj = this.createEditObject();

    try {
      await this.userAccountRest.updateUserAccount(obj);
      toastr.options = this.data.toastrOptions;
      toastr.success(this.translate.instant('_Updated_profile_text'), this.translate.instant("_Success"));
      this.updateLoggedUser(obj);
    }
    catch (err) {
      toastr.options = this.data.toastrOptions;
      toastr.error(err.message, this.translate.instant("_Error"));
    }
  }

  async updateClientData
    () {
    this.isSubmittedClient = false;
    if (this.clientForm.invalid) {
      this.isSubmittedClient = true;
    }

    if (this.isSubmittedClient)
      return;

    var obj = this.createEditClientObject();

    try {
      await this._clientRest.updateClientData(obj);
      toastr.options = this.data.toastrOptions;
      toastr.success(this.translate.instant('_Updated_profile_text'), this.translate.instant("_Success"));
    }
    catch (err) {
      toastr.options = this.data.toastrOptions;
      toastr.error(err.message, this.translate.instant("_Error"));
    }
  }

  createEditObject() {
    var obj = new UserAccountModel();
    obj.id = this.data.user.id;
    obj.username = this.data.user.username;
    obj.firstName = this.formGroup.controls.firstName.value;
    obj.lastName = this.formGroup.controls.lastName.value;
    obj.clientId = this.data.user.clientId;

    return obj;
  }

  createEditClientObject() {
    var obj = this.clientData as ClientModel;
    obj.clientId = this.clientData.id;
    obj.companyName = this.clientForm.controls.clientName.value;
    obj.email = this.clientForm.controls.email.value;
    obj.address = this.clientForm.controls.address.value;
    obj.phone = this.clientForm.controls.phone.value;
    obj.pdvNumber = this.clientForm.controls.pdvNumber.value;
    obj.idNumber = this.clientForm.controls.idNumber.value;
    obj.webAddress = this.clientForm.controls.webAddress.value;
    obj.bankAccountData = this.bankAccounts.controls.map((control) => ({
      bankName: control.get('bankName')?.value,
      bankAccountNumber: control.get('accountNumber')?.value,
    }));
    obj.footerData = this.footerData.controls.map((control) => ({
      header: control.get('header')?.value,
      description: control.get('description')?.value,
    }));
    return obj;
  }

  updateLoggedUser(user: UserAccountModel) {
    this.data.user.firstName = user.firstName;
    this.data.user.lastName = user.lastName;

    sessionStorage.setItem("logged_erp_client", JSON.stringify(this.data.user));
  }

  cancelPasswordReset() {
    var self = this;
    var question_text = this.translate.instant("_Discard_changes_question_text");
    var yes_text = this.translate.instant("_Yes_discard_text");
    var no_text = this.translate.instant("_No_return_text");
    Swal.fire({
      html: question_text,
      icon: "warning",
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: yes_text,
      cancelButtonText: no_text,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: 'btn btn-secondary'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.resetformGroup.reset();
        this.changePassword = false;
        this.isSubmittedPass = false;
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  cancelPinReset() {
    var self = this;
    var question_text = this.translate.instant("_Discard_changes_question_text");
    var yes_text = this.translate.instant("_Yes_discard_text");
    var no_text = this.translate.instant("_No_return_text");
    Swal.fire({
      html: question_text,
      icon: "warning",
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: yes_text,
      cancelButtonText: no_text,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: 'btn btn-secondary'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.resetformGroupPin.reset();
        this.changePin = false;
        this.isSubmittedPin = false;
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  toggleResetPinForm(show: boolean) {
    this.changePin = show;

    if (show && this.resetformGroupPin == undefined) {
      this.createResetPinForm();
    }
  }

  toggleResetPasswordForm(show: boolean) {
    this.changePassword = show;

    if (show && this.resetformGroup == undefined) {
      this.createResetPasswordForm();
    }
  }

  validatePasswordForm() {
    var check: boolean = true;
    if (this.f2.newpassword.value != this.f2.confirmpassword.value) {
      $("#confirmpassword").addClass("is-invalid");
      check = false;
    }
    return check;
  }
  async resetPassword() {
    this.isSubmittedPass = false;
    if (this.resetformGroup.invalid) {
      this.isSubmittedPass = true;
    }
    if (!this.validatePasswordForm()) {
      this.isSubmittedPass = true;
    }
    if (this.isSubmittedPass == true) {
      return;
    }

    var obj = new ChangeUserPasswordModel();
    obj.id = this.data.user.id;
    obj.newPassword = this.f2.newpassword.value;
    obj.currentPassword = this.f2.currentpassword.value;

    try {
      await this.userAccountRest.changePassword(obj);
      this.resetformGroup.reset();
      this.toggleResetPasswordForm(false);
      toastr.options = this.data.toastrOptions;
      toastr.success(this.translate.instant('_Password_changed_text'), this.translate.instant("_Success"));
    }
    catch (err) {
      toastr.options = this.data.toastrOptions;
      toastr.error(err.error['Message'], this.translate.instant("_Error"));
    }
  }

  validatePinForm() {
    var check: boolean = true;
    if (this.f3.newPin.value != this.f3.confirmPin.value) {
      $("#confirmPin").addClass("is-invalid");
      check = false;
    }
    return check;
  }
  async resetPin() {
    this.isSubmittedPin = false;
    if (this.resetformGroupPin.invalid) {
      this.isSubmittedPin = true;
    }
    if (!this.validatePinForm()) {
      this.isSubmittedPin = true;
    }
    if (this.isSubmittedPin == true) {
      return;
    }

    var obj = new ChangeUserPINModel();
    obj.id = this.data.user.id;
    obj.newPIN = this.f3.newPin.value;
    if (this.f3.currentPin.value == '' && this.data.user.pin == undefined)
      obj.currentPIN = null;
    else obj.currentPIN = this.f3.currentPin.value;

    try {
      await this.userAccountRest.changePin(obj);
      this.data.user.pin = this.f3.newPin;
      this.createResetPinForm();
      this.toggleResetPinForm(false);
      toastr.options = this.data.toastrOptions;
      toastr.success(this.translate.instant('_PIN_changed_text'), this.translate.instant("_Success"));
    }
    catch (err) {
      toastr.options = this.data.toastrOptions;
      toastr.error(err.error['Message'], this.translate.instant("_Error"));
    }
  }

  async fetchImages() {
    var response = await this.userAccountRest.getImagesByClientId(this.data.user.clientId);
    if (response.length > 0) {
      var lp = response.find(x => x.key == 'LP');
      if (lp != undefined)
        this.loginUrl = this.imagesUrl + this.data.user.clientId + '/' + lp.imageName;
      var ml = response.find(x => x.key == 'ML');
      if (ml != undefined)
        this.memoUrl = this.imagesUrl + this.data.user.clientId + '/' + ml.imageName;
    }
  }

  openImageUpload(key: string) {
    if (key == 'LP')
      this.loginUpload.nativeElement.click();
    else if (key == 'ML')
      this.memoUpload.nativeElement.click();
  }

  async onFileSelected(event: any, key: string) {
    if (event.target.files && event.target.files.length > 0) {
      if (key == 'LP') {
        this.loginImage = event.target.files[0];
        await this.changeImage('LP');
      }
      else if (key == 'ML') {
        this.memorandumImage = event.target.files[0];
        await this.changeImage('ML');
      }
    }
  }
  async changeImage(key: string) {
    try {
      if (key == 'LP')
        await this.userAccountRest.uploadImage(this.data.user.clientId, key, this.loginImage);
      else if (key == 'ML')
        await this.userAccountRest.uploadImage(this.data.user.clientId, key, this.memorandumImage);
      await this.fetchImages();
      toastr.options = this.data.toastrOptions;
      toastr.success(this.translate.instant('_Image_changed'), this.translate.instant("_Success"));
    }
    catch (err: any) {
      if (err.status == '200') {
        await this.fetchImages();
        toastr.options = this.data.toastrOptions;
        toastr.success(this.translate.instant('_Image_changed'), this.translate.instant("_Success"));
      } else {
        if (err.status == 401)
          this.data.logout();
        else {
          console.log(err);
          toastr.options = this.data.toastrOptions;
          toastr.success(this.translate.instant('_Request_failed_text'), this.translate.instant("_Error"));
        }
      }
    }
  }
}
import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClientUserRoles } from 'src/app/models/clientUserRoles.model';
import { IdModel } from 'src/app/models/id.model';
import { UserAccountModel } from 'src/app/models/userAccount.model';
import { DataService } from 'src/app/services/data.service';
import { UserAccountRestService } from 'src/app/services/userAccount.service';
import { TranslateService } from 'src/app/translate';
import { RetailPlaceRestService } from 'src/app/services/retailPlace.service';
import { RetailPlaceModel } from 'src/app/models/retailPlace.model';
import toastr from 'toastr';

declare var $: any;
declare var Swal: any;
declare var KTMenu: any;

@Component({
    selector: 'erp-page-user-account',
    templateUrl: './userAccount.component.html',
})
export class UserAccountComponent {

    public searchForm: UntypedFormGroup;
    public isActive: any = "null";
    public clientUserRoleId: any = 'null';
    public retailPlaceId: number = null;
    public emptyData: boolean = true;
    public users: UserAccountModel[];
    public userRoles: ClientUserRoles[];
    public retailPlaces: RetailPlaceModel[];
    public editForm: UntypedFormGroup;
    public userRoleAddEditId: number = null;
    public isSubmitted: boolean = false;
    public selectedUser: UserAccountModel;
    public addForm: UntypedFormGroup;

    //pagination
    public page: number = 1;
    public perPage: number;
    public totalData: number;
    public maxPage: number;
    public pages: number[] = [];
    public offset: number = 5;
    public firstPage: number = 1;
    public lastPage: number;
    public counter: number = 0;
    public firstLoad: boolean = true;

    get f1() { return this.editForm.controls; }
    get f2() { return this.addForm.controls; }

    @ViewChild('selectFilterUserRoleId') selectFilterUserRoleId: ElementRef;
    @ViewChild('selectFilterClientId') selectFilterClientId: ElementRef;
    @ViewChild('selectFilterRetailPlaceId') selectFilterRetailPlaceId: ElementRef;
    @ViewChild('closeEditUserModal') closeEditUserModal: ElementRef;
    @ViewChild('closeAddUserModal') closeAddUserModal: ElementRef;
    @ViewChild('searchUserUsername') searchUserUsername: ElementRef;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private userRest: UserAccountRestService,
        public data: DataService,
        private _changeRef: ChangeDetectorRef,
        private translate: TranslateService,
        private retailPlaceRest: RetailPlaceRestService,
    ) {
        this.page = 1;
        this.perPage = 50;
    }

    ngOnInit() {
        KTMenu.createInstances();
        this.createSearchForm();
        this.createAddEditForm();
        this.fetchUserAccount();
        this.fetchUserRoles();
        this.fetchRetailPlaces();
    }

    async fetchUserAccount() {
        try {
            this.emptyData=false;
            var username = this.searchForm.controls.username.value;
            if (this.selectFilterUserRoleId != null)
                this.clientUserRoleId = this.selectFilterUserRoleId.nativeElement.value;
            if (this.selectFilterRetailPlaceId != null)
                this.retailPlaceId = this.selectFilterRetailPlaceId.nativeElement.value;
            var response = await this.userRest.getUserAccounts(username, this.isActive, this.clientUserRoleId, this.data.user.clientId, this.retailPlaceId, this.page, this.perPage);
            this.users = response.data;
            this.totalData = response.totalCount;
            if (this.totalData == 0)
                this.emptyData = true;
            else
                this.emptyData = false

            if (this.firstLoad) {
                this.maxPage = response.totalPages;
                this.maxPage = Math.floor(this.maxPage);
                this.firstPage = 1;
                if (this.maxPage > 5)
                    this.lastPage = 5;
                else
                    this.lastPage = this.maxPage;
                this.enablePrevious();

                this.firstLoad = false;
            }
            this.initPages();
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchUserRoles() {
        try {
            this.userRoles = await this.userRest.getUserClientRoles();
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchRetailPlaces() {
        try {
            var response = await this.retailPlaceRest.getRetailPlaces('', this.data.user.clientId, 1, 100000);
            this.retailPlaces = response.data;
            this._changeRef.detectChanges();
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    createSearchForm() {
        this.searchForm = this.formBuilder.group({
            username: [''],
            isActive: ['null'],
            clientUserRoleId: ['null'],
            retailPlaceId: [0]
        })

        $("#users_filter_retailPlaceId").select2();
    }

    createAddEditForm() {
        this.editForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            username: ['', Validators.required],
            password: [''],
            rePassword: [''],
            pin: [''],
            rePin: [''],
            userRoleAddEditId: [0, Validators.required]
        })

        var self = this;

        this.addForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            username: ['', Validators.required],
            password: ['', Validators.required],
            rePassword: ['', Validators.required],
            pin: ['', Validators.required],
            rePin: ['', Validators.required],
            userRoleAddEditId: [0, Validators.required],
        })
    }

    resetForm() {
        this.isSubmitted = false;
        this.addForm.controls['firstName'].setValue("");
        this.addForm.controls['lastName'].setValue("");
        this.addForm.controls['username'].setValue("");
        this.addForm.controls['password'].setValue("");
        this.addForm.controls['rePassword'].setValue("");
        this.addForm.controls['pin'].setValue("");
        this.addForm.controls['rePin'].setValue("");
        this.addForm.controls['userRoleAddEditId'].setValue(0);
        this.userRoleAddEditId = 0;
        $("#add_user_userRoleId").removeClass("is-invalid");
    }

    async selectUser(user: UserAccountModel) {
        this.selectedUser = user;
        try {
            this.editForm.controls['firstName'].setValue(this.selectedUser.firstName);
            this.editForm.controls['lastName'].setValue(this.selectedUser.lastName);
            this.editForm.controls['username'].setValue(this.selectedUser.username);
            this.editForm.controls['userRoleAddEditId'].setValue(this.selectedUser.clientUserRoleId);
            this.editForm.controls['password'].setValue("");
            this.editForm.controls['rePassword'].setValue("");
            this.editForm.controls['pin'].setValue("");
            this.editForm.controls['rePin'].setValue("");
            
            
            var self = this;
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async toggleUserActivity(userId:number, currentValue:boolean){
        var toggleElement = $("#"+userId.toString());
        var successText = currentValue?"_User_deactivated_text":"_User_activated_text";
        var cancelText = currentValue?"_Deactivation_user_cancelled_text":"_Activation_user_cancelled_text";
        var question_text =  this.translate.instant(currentValue?"_User_deactivation_question_text":"_User_activation_question_text");

        var self = this;
        var yes_text = this.translate.instant("_Yes_text");
        var no_text = this.translate.instant("_No_text");
        Swal.fire({
            html: question_text,
            icon: "question",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-danger'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    var obj = new IdModel();
                    obj.id = userId;

                    await self.userRest.toggleVisibility(obj);
                    self.fetchUserAccount();
                    Swal.fire(self.translate.instant("_Success"), self.translate.instant(successText), 'success');
                }
                catch (err) {
                    Swal.fire(self.translate.instant("_Error"), err.error['Message'], 'danger')
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(self.translate.instant("_Warning"), self.translate.instant(cancelText), 'warning');
            }
        });

        var currentValue = toggleElement.prop('checked') as boolean;
        toggleElement.prop('checked', !currentValue);
    }

    searchUsers(userUsername: string) {
        this.searchForm.controls["username"].setValue(userUsername);
        this.firstLoad = true;
        this.page = 1;
        this.fetchUserAccount();
    }

    resetFilter() {
        this.searchForm.controls['username'].setValue('');
        this.searchForm.controls['clientUserRoleId'].setValue('null');
        this.searchForm.controls['isActive'].setValue('null');
        this.searchForm.controls['retailPlaceId'].setValue(0);
        this.isActive = 'null';
        this.clientUserRoleId = null;
        this.retailPlaceId = null;
        this.firstLoad = true;
        this.page = 1;
        $('#users_filter_retailPlaceId').val(null).trigger("change")
        this.searchUserUsername.nativeElement.value = "";
        this.fetchUserAccount();
    }

    async updateUser() {
        this.isSubmitted = false;
        if (this.editForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateEditForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        var obj = this.createEditObject();

        try {
            this.fetchRetailPlaces();
            await this.userRest.updateUserAccount(obj);
            this.closeEditUserModal.nativeElement.click();
            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Updated_user_text'), this.translate.instant("_Success"));
            this.fetchUserAccount();
        }
        catch (err) {
            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
    }

    createEditObject() {
        var obj = new UserAccountModel();
        obj.id = this.selectedUser.id;
        obj.firstName = this.editForm.controls.firstName.value;
        obj.lastName = this.editForm.controls.lastName.value;
        obj.username = this.editForm.controls.username.value;
        obj.isActive = this.selectedUser.isActive;
        obj.clientUserRoleId = this.userRoleAddEditId;
        obj.clientId = this.data.user.clientId;
        obj.password = this.editForm.controls.password.value;
        obj.pin = this.editForm.controls.pin.value;

        return obj;
    }

    validateEditForm() {
        var check = true;
        if (this.userRoleAddEditId == 0) {
            $("#edit_user_userRoleId").addClass("is-invalid");
            check = false;
        }
        else {
            $("#edit_user_userRoleId").removeClass("is-invalid");
        }

        var password = this.editForm.controls.password.value;
        var rePassword = this.editForm.controls.rePassword.value;

        if (password != rePassword) {
            $("#edit_user_password").addClass("is-invalid");
            check = false;
        }
        else {
            $("#edit_user_password").removeClass("is-invalid");
        }

        var pin = this.editForm.controls.pin.value;
        var rePin = this.editForm.controls.rePin.value;

        if (pin != rePin) {
            $("#edit_user_pin").addClass("is-invalid");
            check = false;
        }
        else {
            $("#edit_user_pin").removeClass("is-invalid");
        }

        return check;
    }

    async addUser() {
        this.isSubmitted = false;
        if (this.addForm.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        var obj = this.createAddObject();

        try {
            await this.userRest.createUserAccount(obj);
            this.closeAddUserModal.nativeElement.click();
            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Added_user_text'), this.translate.instant("_Success"));
            this.fetchUserAccount();
        }
        catch (err) {
            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
    }

    validateAddForm() {
        var check = true;
        if (this.userRoleAddEditId == 0) {
            $("#add_user_userRoleId").addClass("is-invalid");
            check = false;
        }
        else {
            $("#add_user_userRoleId").removeClass("is-invalid");
        }

        var password = this.addForm.controls.password.value;
        var rePassword = this.addForm.controls.rePassword.value;

        if (password != rePassword) {
            $("#add_user_password").addClass("is-invalid");
            check = false;
        }
        else {
            $("#add_user_password").removeClass("is-invalid");
        }

        var pin = this.addForm.controls.pin.value;
        var rePin = this.addForm.controls.rePin.value;

        if (pin != rePin) {
            $("#add_user_pin").addClass("is-invalid");
            check = false;
        }
        else {
            $("#add_user_pin").removeClass("is-invalid");
        }

        return check;
    }

    createAddObject() {
        var obj = new UserAccountModel();
        obj.firstName = this.addForm.controls.firstName.value;
        obj.lastName = this.addForm.controls.lastName.value;
        obj.username = this.addForm.controls.username.value;
        obj.isActive = true;
        obj.clientUserRoleId = this.userRoleAddEditId;
        obj.clientId = this.data.user.clientId;
        obj.password = this.addForm.controls.password.value;
        obj.pin = this.addForm.controls.pin.value;

        return obj;
    }

    closeAddModal() {
        var self = this;
        var question_text = this.translate.instant("_Cancel_question_text");
        var yes_text = this.translate.instant("_Yes_cancel_text");
        var no_text = this.translate.instant("_No_return_text");
        Swal.fire({
            html: question_text,
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-secondary'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                self.closeAddUserModal.nativeElement.click();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                self.resetFilter();
            }
        });
    }

    closeEditModal() {
        var self = this;
        var question_text = this.translate.instant("_Cancel_question_text");
        var yes_text = this.translate.instant("_Yes_cancel_text");
        var no_text = this.translate.instant("_No_return_text");
        Swal.fire({
            html: question_text,
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-secondary'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                self.closeEditUserModal.nativeElement.click();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                self.resetFilter();
            }
        });
    }

    negateEnter(event: KeyboardEvent) {
        if (event.key == "Enter")
            event.preventDefault();
    }

    //pagination
    initPages() {
        this.pages = [];
        for (var i = this.firstPage; i <= this.lastPage; i++) {
            this.pages.push(i);
        }
    }

    nextPage() {
        if ($("#kt_customers_table_next").hasClass("disabled"))
            return;

        if (this.page == 1 && this.firstPage == 1) {
            this.page++;
        }

        else {
            this.page++;
            if (this.counter == 1) {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage) {
                    if(this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else 
                        this.firstPage=1;
                    this.lastPage = this.maxPage;
                }
                this.counter = 0;
            }
            else {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else {
                    if(this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else 
                        this.firstPage = 1;
                    this.lastPage = this.maxPage;
                }
            }
            this.counter++;
        }
        this.fetchUserAccount();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    prevpage() {
        if ($("#kt_customers_table_previous").hasClass("disabled"))
            return;

        this.page--;
        if (this.counter == 1) {
            if (this.maxPage > this.lastPage + this.offset) {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if(this.maxPage>this.firstPage+this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage=this.maxPage;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                    if(this.maxPage>this.firstPage+this.offset)
                        this.lastPage = this.firstPage + this.offset;
                    else
                        this.lastPage=this.maxPage;
                this.initPages();
            }
            this.counter = 0;
        }
        else {
            if (this.maxPage > this.lastPage + this.offset) {
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if(this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else 
                    this.lastPage = this.maxPage;
                this.initPages();
            }
        }
        this.counter++;
        this.fetchUserAccount();
        this.enablePrevious();
        this._changeRef.detectChanges();

    }

    goToPage(p: number) {
        this.page = p;
        this.fetchUserAccount();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    morePages() {
        if ($("#kt_customers_table_morePages").hasClass("disabled"))
            return;
        if (this.maxPage > this.lastPage + this.offset) {
            this.firstPage = this.lastPage;
            this.lastPage = this.firstPage + this.offset;
        }
        else {
            this.firstPage = this.maxPage - this.offset;
            this.lastPage = this.maxPage;
        }
        this.page = this.firstPage;
        this.fetchUserAccount();
        this.initPages();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }


    perPageHandler() {
        this.firstLoad = true;
        this.page = 1;
        this.fetchUserAccount();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    enablePrevious() {
        if (this.page > 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").removeClass("disabled");
            })
        }
        else if (this.page == 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page > 1 && this.page != this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        if (this.maxPage == 0) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
                $("#morePages").addClass("disabled");
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        if (this.maxPage <= 5 || this.lastPage == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").removeClass("disabled");
            })
        }
    }
}


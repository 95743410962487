import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GroupModel } from 'src/app/models/group.model';
import { GroupTypeModel } from 'src/app/models/groupType.model';
import { TaxModel } from 'src/app/models/tax.model';
import { ClientRestService } from 'src/app/services/client.service';
import { DataService } from 'src/app/services/data.service';
import { GroupRestService } from 'src/app/services/group.service';
import { GroupTypeRestService } from 'src/app/services/groupType.service';
import { TranslateService } from 'src/app/translate';
import toastr from 'toastr'

declare var $: any;
declare var KTMenu: any;
declare var Swal: any;

@Component({
    selector: 'erp-group',
    templateUrl: './group.component.html',
})
export class GroupComponent {

    public emptyData: boolean = true;
    public groups: GroupModel[];
    public searchForm: UntypedFormGroup;
    public groupTypeId: number = null;
    public formGroup: UntypedFormGroup;
    public groupTypeAddEditId: number = null;
    public groupTypes: GroupTypeModel[];
    public isSubmitted: boolean = false;
    public selectedGroup: GroupModel;
    public taxes:TaxModel[];
    public taxAddEditId:number=null;
    public isRawGroup:boolean=false;

    //pagination
    public page: number = 1;
    public perPage: number;
    public totalData: number;
    public maxPage: number;
    public pages: number[] = [];
    public offset: number = 5;
    public firstPage: number = 1;
    public lastPage: number;
    public counter: number = 0;
    public firstLoad: boolean = true;

    get f() { return this.formGroup.controls; }

    @ViewChild('selectFilterGroupTypeId') selectFilterGroupTypeId: ElementRef;
    @ViewChild('selectFilterClientId') selectFilterClientId: ElementRef;
    @ViewChild('selectedAddEditGroupType') selectedAddEditGroupType: ElementRef;
    @ViewChild('closeAddGroupModal') closeAddGroupModal: ElementRef;
    @ViewChild('closeEditGroupModal') closeEditGroupModal: ElementRef;

    constructor(
        private groupRest: GroupRestService,
        public clientRest: ClientRestService,
        public groupTypeRest: GroupTypeRestService,
        private formBuilder: UntypedFormBuilder,
        private _changeRef: ChangeDetectorRef,
        public data: DataService,
        private translate: TranslateService) {
        this.page = 1;
        this.perPage = 50;
    }

    ngOnInit() {
        KTMenu.createInstances();
        this.createSearchForm();
        this.fetchGroups();
        this.fetchGroupTypes();
        this.fetchTaxes();
        this.createAddEditForm();
    }

    ngAfterViewInit(){
        $('#kt_modal_add_group').on('shown.bs.modal', ()=>{
            this._changeRef.detectChanges();
            $("#add_group_taxId").select2().val(this.taxes.find(x=>x.clientId==0 || x.clientId==null).id).trigger('change');
        });
    }

    async fetchGroups() {
        try {
            this.emptyData=false;
            if (this.selectFilterGroupTypeId != null)
                this.groupTypeId = this.selectFilterGroupTypeId.nativeElement.value;

            var name = this.searchForm.controls.name.value;

            var response = await this.groupRest.getGroups(name, this.groupTypeId, this.data.user.clientId, this.page, this.perPage);
            this.groups = response.data;

            this.totalData = response.totalCount;
            if (this.totalData == 0)
                this.emptyData = true;
            else
                this.emptyData = false

            if (this.firstLoad) {
                this.maxPage = response.totalPages;
                this.maxPage = Math.floor(this.maxPage);
                this.firstPage = 1;
                if (this.maxPage > 5)
                    this.lastPage = 5;
                else
                    this.lastPage = this.maxPage;

                this.enablePrevious();
                this.firstLoad = false;
            }
            this.initPages();
        }
        catch (err) {
            if (err.status == 401) {
                this.data.logout();
            }
        }
    }

    async fetchTaxes(){
        var response = await this.groupRest.getTaxesByClientId(this.data.user.clientId);
        this.taxes = response;
        $('#add_group_taxId').select2().val(this.taxes.find(x=>x.clientId==null || x.clientId==0).id).trigger('change');
    }

    async fetchGroupTypes() {
        var response = await this.groupTypeRest.getGroupTypes('', this.data.user.clientId, 1, 100000);
        this.groupTypes = response.data;
    }

    async addGroup() {
        this.isSubmitted = false;
        if (this.formGroup.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateAddForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        var obj = this.createAddObject();

        try {
            await this.groupRest.createGroup(obj);
            this.closeAddGroupModal.nativeElement.click();
            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Added_group_text'), this.translate.instant("_Success"));
            this.fetchGroups();
        }
        catch (err) {
            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
    }

    async updateGroup() {
        this.isSubmitted = false;
        if (this.formGroup.invalid) {
            this.isSubmitted = true;
        }
        if (!this.validateEditForm()) {
            this.isSubmitted = true;
        }
        if (this.isSubmitted == true) {
            return;
        }

        var obj = this.createEditObject();

        try {
            await this.groupRest.updateGroup(obj);
            this.closeEditGroupModal.nativeElement.click();
            toastr.options = this.data.toastrOptions;
            toastr.success(this.translate.instant('_Updated_group_text'), this.translate.instant("_Success"));
            this.fetchGroups();
        }
        catch (err) {
            toastr.options = this.data.toastrOptions;
            toastr.error(err.error['Message'], this.translate.instant("_Error"));
        }
    }

    resetFilter() {
        this.searchForm.controls['name'].setValue(null);
        this.searchForm.controls['groupTypeId'].setValue('');
        this.groupTypeId = null;

        $('#groups_filter_groupTypeId').val(null).trigger("change")

        this.firstLoad = true;
        this.page = 1;
        this.fetchGroups();
    }

    searchGroups() {
        this.firstLoad = true;
        this.page = 1;
        this.fetchGroups();
    }

    createAddObject() {
        var obj = new GroupModel();
        obj.name = this.formGroup.controls.name.value;
        obj.groupTypeId = parseInt(this.selectedAddEditGroupType.nativeElement.value);
        obj.clientId = parseInt(this.data.user.clientId);
        obj.taxId = this.taxAddEditId;
        obj.isRawGroup=this.isRawGroup;

        return obj;
    }

    createEditObject() {
        var obj = new GroupModel();
        obj.id = this.selectedGroup.id;
        obj.name = this.formGroup.controls.name.value;
        obj.groupTypeId = parseInt(this.selectedAddEditGroupType.nativeElement.value);
        obj.clientId = parseInt(this.data.user.clientId);
        obj.taxId = this.taxAddEditId;
        obj.isRawGroup=this.isRawGroup;

        return obj;
    }

    createSearchForm() {
        this.searchForm = this.formBuilder.group({
            name: [''],
            groupTypeId: [0],
        })

        $("#groups_filter_groupTypeId").select2();
    }

    createAddEditForm() {
        this.formGroup = this.formBuilder.group({
            name: ['', Validators.required],
            groupTypeAddEditId: [0, Validators.required],
            taxAddEditId: [0, Validators.required],
            isRawGroup: [false]
        })

        $("#add_group_groupTypeId").select2();
        $("#add_group_taxId").select2();

        var self = this;
        $('#add_group_groupTypeId').on("change", function () {
            self.groupTypeAddEditId = $(this).val();
        });
        $('#add_group_taxId').on("change", function () {
            self.taxAddEditId = $(this).val();
        });

        $("#edit_group_groupTypeId").select2();
        $("#edit_group_taxId").select2();

        $('#edit_group_groupTypeId').on("change", function () {
            if($(this).val()=="")
                self.groupTypeAddEditId = 0;
            else 
                self.groupTypeAddEditId = $(this).val();
        });
        $('#edit_group_taxId').on("change", function () {
            self.taxAddEditId = $(this).val();
        });
    }

    selectGroup(group: GroupModel) {
        this.selectedGroup = group;
        this.formGroup.controls['name'].setValue(this.selectedGroup.name);
        this.formGroup.controls['taxAddEditId'].setValue(this.selectedGroup.taxId);
        this.formGroup.controls['groupTypeAddEditId'].setValue(this.selectedGroup.groupTypeId);
        var self = this;
        $('#edit_group_groupTypeId').select2().val(this.selectedGroup.groupTypeId).trigger("change");
        $("#edit_group_taxId").select2().val(this.selectedGroup.taxId).trigger("change");
        $('#edit_user_retailPlaceId').on("change", function () {
            self.groupTypeAddEditId = $(this).val();
        });
        this.isRawGroup=group.isRawGroup;
    }

    resetForm() {
        this.isSubmitted=false;
        this.formGroup.controls['name'].setValue("");
        this.formGroup.controls['groupTypeAddEditId'].setValue(0);
        this.groupTypeAddEditId = 0;
        this.taxAddEditId=0;
        $('#add_group_groupTypeId').val(null).trigger("change")
        $('#add_group_taxId').val(null).trigger("change")
        $("#add_group_groupTypeId").nextAll('span').first().removeClass("is-invalid");
        this.isRawGroup=false;
    }

    validateAddForm() {
        var check = true;
        if (this.groupTypeAddEditId == null) {
            $("#add_group_groupTypeId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#add_group_groupTypeId").nextAll('span').first().removeClass("is-invalid");
        }

        if (this.taxAddEditId == null) {
            $("#add_group_taxId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#add_group_taxId").nextAll('span').first().removeClass("is-invalid");
        }

        return check;
    }

    validateEditForm() {
        var check = true;
        if (this.groupTypeAddEditId == 0) {
            $("#edit_group_groupTypeId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#edit_group_groupTypeId").nextAll('span').first().removeClass("is-invalid");
        }
        if (this.taxAddEditId == 0) {
            $("#edit_group_taxId").nextAll('span').first().addClass("is-invalid");
            check = false;
        }
        else {
            $("#edit_group_taxId").nextAll('span').first().removeClass("is-invalid");
        }
        return check;
    }

    closeAddModal() {
        var self = this;
        var question_text = this.translate.instant("_Cancel_question_text");
        var yes_text = this.translate.instant("_Yes_cancel_text");
        var no_text = this.translate.instant("_No_return_text");
        Swal.fire({
            html: question_text,
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-secondary'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                self.closeAddGroupModal.nativeElement.click();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }

    closeEditModal() {
        var self = this;
        var question_text = this.translate.instant("_Cancel_question_text");
        var yes_text = this.translate.instant("_Yes_cancel_text");
        var no_text = this.translate.instant("_No_return_text");
        Swal.fire({
            html: question_text,
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: yes_text,
            cancelButtonText: no_text,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: 'btn btn-secondary'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                self.closeEditGroupModal.nativeElement.click();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }

    //pagination
    initPages() {
        this.pages = [];
        for (var i = this.firstPage; i <= this.lastPage; i++) {
            this.pages.push(i);
        }
    }

    nextPage() {
        if ($("#kt_customers_table_next").hasClass("disabled"))
            return;

        if (this.page == 1 && this.firstPage == 1) {
            this.page++;
        }

        else {
            this.page++;
            if (this.counter == 1) {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.firstPage++;
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage) {
                    if(this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else 
                        this.firstPage=1;
                    this.lastPage = this.maxPage;
                }
                this.counter = 0;
            }
            else {
                if (this.maxPage > (this.lastPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else if (this.maxPage > (this.firstPage + this.offset)) {
                    this.lastPage = this.firstPage + this.offset;
                    this.initPages();
                }

                else {
                    if(this.maxPage - this.offset > 0)
                        this.firstPage = this.maxPage - this.offset;
                    else 
                        this.firstPage = 1;
                    this.lastPage = this.maxPage;
                }
            }
            this.counter++;
        }
        this.fetchGroups();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    prevpage() {
        if ($("#kt_customers_table_previous").hasClass("disabled"))
            return;

        this.page--;
        if (this.counter == 1) {
            if (this.maxPage > this.lastPage + this.offset) {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if(this.maxPage>this.firstPage+this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else
                    this.lastPage=this.maxPage;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                    if(this.maxPage>this.firstPage+this.offset)
                        this.lastPage = this.firstPage + this.offset;
                    else
                        this.lastPage=this.maxPage;
                this.initPages();
            }
            this.counter = 0;
        }
        else {
            if (this.maxPage > this.lastPage + this.offset) {
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }
            else {
                if (this.page == 2)
                    this.firstPage = 1;
                else if (this.page != 1 && (this.firstPage - 1) > 1)
                    this.firstPage--;
                else
                    this.firstPage = 1;
                if(this.maxPage > this.firstPage + this.offset)
                    this.lastPage = this.firstPage + this.offset;
                else 
                    this.lastPage = this.maxPage;
                this.initPages();
            }
        }
        this.counter++;
        this.fetchGroups();
        this.enablePrevious();
        this._changeRef.detectChanges();

    }

    goToPage(p: number) {
        this.page = p;
        this.fetchGroups();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    morePages() {
        if ($("#kt_customers_table_morePages").hasClass("disabled"))
            return;
        if (this.maxPage > this.lastPage + this.offset) {
            this.firstPage = this.lastPage;
            this.lastPage = this.firstPage + this.offset;
        }
        else {
            this.firstPage = this.maxPage - this.offset;
            this.lastPage = this.maxPage;
        }
        this.page = this.firstPage;
        this.fetchGroups();
        this.initPages();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }


    perPageHandler() {
        this.firstLoad = true;
        this.page = 1;
        this.fetchGroups();
        this.enablePrevious();
        this._changeRef.detectChanges();
    }

    enablePrevious() {
        if (this.page > 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").removeClass("disabled");
            })
        }
        else if (this.page == 1) {
            $(document).ready(function () {
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page > 1 && this.page != this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        if (this.maxPage == 0) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
                $("#morePages").addClass("disabled");
                $("#kt_customers_table_previous").addClass("disabled");
            })
        }

        if (this.page == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_next").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_next").removeClass("disabled");
            })
        }

        if (this.maxPage <= 5 || this.lastPage == this.maxPage) {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").addClass("disabled");
            })
        }

        else {
            $(document).ready(function () {
                $("#kt_customers_table_morePages").removeClass("disabled");
            })
        }
    }
}


import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ArticleRestService } from 'src/app/services/article.service';
import { CustomerSupplierRestService } from 'src/app/services/customerSupplier.service';
import { DataService } from 'src/app/services/data.service';
import { GroupRestService } from 'src/app/services/group.service';
import { SignOffRestService } from 'src/app/services/sign-off.service';
import { PaymentTypeRestService } from 'src/app/services/payment-type.service';
import { WarehouseRestService } from 'src/app/services/warehouse.service';
import { GroupModel } from 'src/app/models/group.model';
import { TranslateService } from 'src/app/translate';
import { ArticleModel } from 'src/app/models/article.model';
import { TaxModel } from 'src/app/models/tax.model';
import { PaymentTypeModel } from 'src/app/models/paymentType.model';
import { WarehouseModel } from 'src/app/models/warehouse.model';
import { CustomerSuppliersModel } from 'src/app/models/customerSupplier.model';
import toastr from 'toastr';
import { SignOffModel } from 'src/app/models/documents/signOff.model';
import { SignOffArticleModel } from 'src/app/models/documents/signOff.model';
import { minValidator } from 'src/app/validators/minValidator';
import { CurrencyModel } from 'src/app/models/currency.model';
import { CurrencyRestService } from 'src/app/services/currency.service';
import { ItemLookupComponent } from '../item-lookup/item-lookup.component';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { UserAccountModel } from 'src/app/models/userAccount.model';
import { SignOffSaveModel } from 'src/app/models/documents/pageSaveModels/signOffSave.model';
import { UserAccountRestService } from 'src/app/services/userAccount.service';
import { DocumentPdfComponent } from '../document-pdf/document-pdf.component';

declare var $: any;
declare var Swal: any;
declare var Inputmask: any;
declare var KTMenu: any;

@Component({
  selector: 'app-sign-off',
  templateUrl: './sign-off.component.html',
})
export class SignOffComponent  {
  public selectedRow: string = undefined;
  public signOffs: SignOffModel[];
  public groups: GroupModel[];
  public operators: UserAccountModel[];
  public taxes: TaxModel[];
  public warehouses: WarehouseModel[];
  public currencies: CurrencyModel[];
  public emptyData: boolean = false;
  public searchForm: UntypedFormGroup;
  public addForm: UntypedFormGroup;
  dateFrom: string;
  dateTo: string;
  issuerName: string = '';
  public isSubmitted: boolean;
  public selectedSignOff: SignOffModel;
  public selectedSignOffWarehouse: WarehouseModel;
  public addWarehouseId: number = 0;
  public addCurrencyId: number = 0;
  public addType: string = '';
  public date: Date = new Date();
  public searchIssuerName: string;
  public filterWarehouseId: number;
  public headerForm: UntypedFormGroup;
  get hForm() { return this.headerForm.controls; }

  public headerFormEnabled: boolean;
  public itemFormEnabled: boolean;

  public creatingSignOff: SignOffModel;
  public activeSignOffPreEdit: SignOffModel;
  public selectedArticle: ArticleModel;
  public totalMPC: number = 0;
  public totalVPC: number = 0;
  public totalNC: number = 0;
  tableSetUp: boolean = false;
  public mpcDisplay: number;
  public ncDisplay: number;
  public addItemForm: UntypedFormGroup;
  public selectedCurrency: string;
  get iForm() { return this.addItemForm.controls; }
  selectionModalTimer: any;
  enterCount: number = 0;
  creationInProgress: boolean = false;
  public previousWarehouseId: number;

  @ViewChild('closeAddSignOffHeaderModal') closeAddSignOffHeaderModal: ElementRef;
  @ViewChild('closeAddSignOffItemsModal') closeAddSignOffItemsModal: ElementRef;
  @ViewChild('closeEditSignOffHeaderModal') closeEditSignOffHeaderModal: ElementRef;
  @ViewChild('closeEditSignOffItemsModal') closeEditSignOffItemsModal: ElementRef;
  @ViewChild('selectAddSignOffCurrencyId') selectAddSignOffCurrencyId: ElementRef;
  @ViewChild('selectAddSignOffType') selectAddSignOffType: ElementRef;
  @ViewChild('selectAddSignOffWarehouseId') selectAddSignOffWarehouseId: ElementRef;
  @ViewChild('selectEditSignOffIssuerId') selectEditSignOffCustomerId: ElementRef;
  @ViewChild('selectEditSignOffCurrencyId') selectEditSignOffCurrencyId: ElementRef;
  @ViewChild('selectEditSignOffWarehouseId') selectEditSignOffWarehouseId: ElementRef;
  @ViewChild('closeSelectItemModal') closeSelectItemModal: ElementRef;
  @ViewChild('headerTrigger') headerTrigger: ElementRef;
  @ViewChild('itemsTrigger') itemsTrigger: ElementRef;
  @ViewChild('listTrigger') listTrigger: ElementRef;
  @ViewChild('signOffNumberOpen') signOffNumberOpen: ElementRef;
  @ViewChild('signOffNumberClose') signOffNumberClose: ElementRef;
  @ViewChild('firstHeaderForm') firstHeaderFormElement: ElementRef;
  @ViewChild('itemCodeInput') itemCodeInput: ElementRef;
  @ViewChild('signOffNumberInput') signOffNumberInput: ElementRef;
  @ViewChild('selectFilterWarehouseId') selectFilterWarehouseId: ElementRef;
  @ViewChild('addItemsButton') addItemsButton: ElementRef;
  @ViewChild('saveHeaderAddButton') saveHeaderAddButton: ElementRef;
  @ViewChild('saveHeaderEditButton') saveHeaderEditButton: ElementRef;
  @ViewChild('saveEditItemButton') saveEditItemButton: ElementRef;
  @ViewChild('addItemButton') addItemButton: ElementRef;
  @ViewChild('amountInput') amountInput: ElementRef;
  @ViewChild(ItemLookupComponent) itemLookupComponent: ItemLookupComponent;
  @ViewChild(DocumentPdfComponent) documentPdfComponent: DocumentPdfComponent;
  
  public showItems: boolean = false;
  public setUpComplete: boolean = false;
  public editEnabled: boolean = false;
  public startedEditing: boolean = false;
  public showIssuerError: boolean = false;
  public nextNumber: number;
  public addEnabled: boolean = false;
  public changedArrivalDate: boolean = false;
  public headerSaved: boolean = false;
  public editingItems: boolean = false;
  public newSignOffActive: boolean = false;
  public totalPaymentsAmount: number = 0;
  public editingItem: SignOffArticleModel;
  public editingItemIndex: number;
  public documentArticles: ArticleModel[];
  public onHeaderTab: boolean = false;
  public signOffTypes:string[];
  public shiftPressed:boolean=false;
  public showPdf:boolean=false;
  public pdfSource: any;
  public activeSignOffNumber:string = '';

  //pagination
  public page: number = 1;
  public perPage: number;
  public totalData: number;
  public maxPage: number;
  public pages: number[] = [];
  public offset: number = 5;
  public firstPage: number = 1;
  public lastPage: number;
  public counter: number = 0;
  public firstLoad: boolean = true;
  public resizeObserver: ResizeObserver;

  constructor(
      private signOffRest: SignOffRestService,
      private articleRestService: ArticleRestService,
      private groupRestService: GroupRestService,
      private operatorService: UserAccountRestService,
      private paymentTypeRestService: PaymentTypeRestService,
      private warehouseRestService: WarehouseRestService,
      private currencyRestService: CurrencyRestService,
      private datePipe: DatePipe,
      private _changeRef: ChangeDetectorRef,
      public data: DataService,
      private formBuilder: UntypedFormBuilder,
      private translate: TranslateService,
      private router: Router
  ) {
      this.page = 1;
      this.perPage = 50;
  }

  @HostListener('document:keydown', ['$event'])
  checkForShiftPress(event: KeyboardEvent) {
    if(event.key=='Shift'){
      this.shiftPressed=true;
    }
  }
  @HostListener('document:keyup', ['$event'])
  checkForShiftRelease(event: KeyboardEvent) {
    if(event.key=='Shift'){
      this.shiftPressed=false;
    }
  }
  public loopHeaderTab(event: KeyboardEvent, lastElement:boolean){
      if(lastElement){
          if(event.key=='Tab' && !this.shiftPressed){
              event.preventDefault();
              this.firstHeaderFormElement.nativeElement.focus();
          }
      }else{
          if(event.key=='Tab' && this.shiftPressed){
              event.preventDefault();
              if(this.startedEditing)
                  this.saveHeaderEditButton.nativeElement.focus();
              else
                  this.saveHeaderAddButton.nativeElement.focus();
          }
      }
  }
  public loopItemTab(event: KeyboardEvent, lastElement:boolean, potentialFirst:boolean=false){
      if(lastElement){
          if(event.key=='Tab' && !this.shiftPressed){
              event.preventDefault();
              if(this.editingItem==null || this.editingItem==undefined)
                  this.itemCodeInput.nativeElement.focus();
              else 
                  this.amountInput.nativeElement.focus();
          }
      }else{
          if(event.key=='Tab' && this.shiftPressed){
              if(potentialFirst){
                  if(this.editingItem!=null && this.editingItem!=undefined){
                      event.preventDefault();
                      this.saveEditItemButton.nativeElement.focus();
                  }
              }else{
                  event.preventDefault();
                  if(this.editingItem==null || this.editingItem==undefined)
                      this.addItemButton.nativeElement.focus();
                  else 
                      this.saveEditItemButton.nativeElement.focus();
              }
          }
      }
  }

  async ngOnInit() {
      KTMenu.createInstances();
      this.getSignOffTypes();
      this.fetchSignOffs();
      this.fetchCurrencies();
      this.createSearchForm();
      this.createHeaderForm();
      this.createAddItemForm();
      await this.fetchWarehouses();
  }

  ngAfterViewInit() {
    Object.keys(this.headerForm.controls).forEach(name => {
        let control = this.headerForm.get(name);
        control.valueChanges.subscribe(value => this.updateCreatingSignOff(name, value));
    })

    $(this.headerTrigger.nativeElement).on('shown.bs.tab', () => {
        this.firstHeaderFormElement.nativeElement.focus();
    });

      $(this.itemsTrigger.nativeElement).on('shown.bs.tab', () => {
        if(this.editingItem==null || this.editingItem==undefined)
            this.itemCodeInput.nativeElement.focus();
        else 
            this.amountInput.nativeElement.focus();
      });

      $("#kt_modal_signOff_number").on('shown.bs.modal', () => {
          this.signOffNumberInput.nativeElement.focus();
      });
      $("#kt_modal_signOff_number").on('hidden.bs.modal', () => {
        this.firstHeaderFormElement.nativeElement.focus();
    });
  }

  async createSearchForm() {
      this.searchForm = this.formBuilder.group({
          filterRetailPlaceId: [null],
          filterWarehouseId: [null]
      })

      $("#signOff_filter_warehouseId").select2();
      $('#signOff_filter_warehouseId').on("change", function () {
          self.selectFilterWarehouseId.nativeElement.value = $(this).val();
          self.filterWarehouseId = $(this).val();
      });

      var start = new Date(new Date().getFullYear(), 0, 1);
      var end = new Date();
      var self = this;
      $("#kt_daterangepicker_filter").daterangepicker({
          startDate: start,
          endDate: end,
          autoApply: true,
          autoUpdateInput: true,
          locale: {
              format: 'DD/MM/YYYY',
              separator: '-'
          }
      });
      $("#kt_daterangepicker_filter").on('apply.daterangepicker', function (ev, picker) {
          self.dateFrom = picker.startDate.format('YYYY-MM-DD');
          self.dateTo = picker.endDate.format('YYYY-MM-DD');
          $("#searchByCustomerInput").focus();
          self.fetchSignOffs();
      });
      $("#kt_daterangepicker_filter").on('cancel.daterangepicker', function (ev, picker) {
          picker.setStartDate(new Date());
          picker.setEndDate(new Date());
          self.dateFrom = picker.startDate.format('YYYY-MM-DD');
          self.dateTo = picker.endDate.format('YYYY-MM-DD');
          self.fetchSignOffs();
      });
      var im = new Inputmask("99/99/9999-99/99/9999");
      im.mask($("#kt_daterangepicker_filter"));
  }

  async resetFilter() {
      this.searchForm.reset();
      $("#signOff_filter_warehouseId").val(null).trigger("change");
      var pickerElement = $("#kt_daterangepicker_filter");
      var today = new Date();
      $(pickerElement).data('daterangepicker').setStartDate(new Date(today.getFullYear(), 0, 1, 0, 0 ,0));
      $(pickerElement).data('daterangepicker').setEndDate(new Date());
      $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
  }

  filterByDate(event: KeyboardEvent) {
      if (event.key == "Enter" || event.key == "Tab") {
          var pickerElement = $("#kt_daterangepicker_filter");
          $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
      }
  }

  async fetchCurrencies() {
      try {
          this.currencies = await this.currencyRestService.getCurrencies();
      }
      catch (err) {
          if (err.status == 401) {
              this.data.logout();
          }
      }
  }

  async getSignOffTypes() {
    try {
        this.signOffTypes = await this.signOffRest.getTypes();
        this.addType=this.signOffTypes[0];
    }
    catch (err) {
        if (err.status == 401) {
            this.data.logout();
        }
    }
}

  async fetchWarehouses() {
      try {
          this.warehouses = await this.warehouseRestService.getWarehousesForClient(this.data.user.clientId);
      }
      catch (err) {
          if (err.status == 401) {
              this.data.logout();
          }
      }
  }

  async fetchSignOffs() {
      try {
          this.emptyData = false;
          if (this.dateFrom == undefined)
              this.dateFrom = this.datePipe.transform((new Date(new Date().getFullYear(), 0, 1)), 'yyyy-MM-dd');
          if (this.dateTo == undefined)
              this.dateTo = this.datePipe.transform((new Date()), 'yyyy-MM-dd');
          var response = await this.signOffRest.getSignOffs(this.issuerName, this.filterWarehouseId, this.dateFrom, this.dateTo, this.data.user.clientId, this.page, this.perPage);
          this.signOffs = response.data;
          
          this.totalData = response.totalCount;
          if (this.totalData == 0)
              this.emptyData = true;
          else
              this.emptyData = false

          if (this.firstLoad) {
              this.maxPage = response.totalPages;
              this.maxPage = Math.floor(this.maxPage);
              this.firstPage = 1;
              if (this.maxPage > 5)
                  this.lastPage = 5;
              else
                  this.lastPage = this.maxPage;
              this.enablePrevious();

              this.firstLoad = false;
          }
          this.initPages();
      }
      catch (err) {
          if (err.status == 401) {
              this.data.logout();
          }
      }
  }

  async getNextNumber() {
      this.nextNumber = await this.signOffRest.getNextNumber(this.data.user.clientId);
      if (this.creatingSignOff.documentNumber == undefined) {
          this.creatingSignOff.documentNumber = this.nextNumber;
          this.creatingSignOff.signOffNumber = this.nextNumber + '/' + ((new Date()).getFullYear() - 2000);
          this.activeSignOffNumber = this.creatingSignOff.signOffNumber;
      }
  }

  resetActiveSignOff() {
      this.creatingSignOff = new SignOffModel();
      this.creatingSignOff.clientId=this.data.user.clientId;
      this.creatingSignOff.articles = [];
      this.creatingSignOff.totalMPC=0;
      this.creatingSignOff.totalNC=0;
      this.creatingSignOff.totalVPC=0;
      this.activeSignOffPreEdit = this.creatingSignOff;
  }



  updateCreatingSignOff(name: string, value: any) {
      this.creatingSignOff[name] = value;
      if (name == 'warehouseId' && this.warehouses!=undefined && this.warehouses.length > 0) 
        this.creatingSignOff.warehouseName = this.warehouses.find(x => x.id == value).name;
  }

  async searchSignOffs(issuerName: string) {
      this.issuerName = issuerName;
      this.firstLoad = true;
      this.page = 1;
      this.fetchSignOffs();
  }

  async fetchSignOffPdf(id: string) {
    var response = await this.signOffRest.getSignOffPDF(this.data.user.clientId, id);
    this.pdfSource = window.URL.createObjectURL(response);
  }

  async exportPDF() {
        await this.fetchSignOffPdf(this.creatingSignOff.id);
        if (this.pdfSource){
            this.showPdf=true;
            this.documentPdfComponent.setSource(this.pdfSource);
        }
        else {
            toastr.options = this.data.toastrOptions;
            toastr.warning(this.translate.instant("_No_data"), this.translate.instant("_Warning"));
        }
    }
    closePDF(){
        this.showPdf=false;
    }

  openItemsOnEmpty($event: KeyboardEvent = null) {
      var fieldValue = $("#itemCodeInput").val();
      if (!this.itemFormEnabled || (fieldValue != '' && fieldValue != undefined))
          return;

      if ($event != null) {
          if ($event.key == "Enter") {
              this.enterCount++;
              clearTimeout(this.selectionModalTimer);
              this.selectionModalTimer = setTimeout(() => {
                  this.enterCount = 0;
              }, 500);

              if (this.enterCount != 2)
                  return;

              this.itemLookupComponent.openItemsModal();
          }
      } else {
          this.itemLookupComponent.openItemsModal();
      }
  }

  toggleItems(toggle: boolean) {
      this.showItems = toggle;
  }
  toggleDocNav(toggle: boolean) {
      this.onHeaderTab = toggle;
  }

  switchToArticles() {
      this.itemsTrigger.nativeElement.click();
      this.startAddingItems();
  }

  switchToHeader() {
      this.headerTrigger.nativeElement.click();
  }

  viewDocuments() {
      this.toggleItems(false);
      this.toggleDocNav(false);
      this.resetForm();
      this.resetItemForm();
      this.disableForm();
      this.headerSaved = false;
      this.startedEditing = false;
      this.editEnabled = false;
      this.addEnabled = false;
      this.editingItems = false;
  }

  selectSignOff(signOff: SignOffModel, switchToHeaderTab: boolean = true) {
      if (switchToHeaderTab)
          this.headerTrigger.nativeElement.click();

      this.creatingSignOff = signOff;
      this.activeSignOffNumber = this.creatingSignOff.signOffNumber;      
      this.selectedCurrency = this.currencies[0].code;

      $('#add_signOff_warehouseId').val(this.creatingSignOff.warehouseId).trigger("change");
      $('#add_signOff_type').val(this.creatingSignOff.signOffType).trigger("change");
      $("#kt_bill_date_add_picker").data('daterangepicker').setStartDate(new Date(this.creatingSignOff.date));
      this.hForm.date.setValue(this.creatingSignOff.date);
      this.addEnabled = false;
      this.editEnabled = true;
      this.startedEditing = false;

      this.activeSignOffPreEdit = this.makePreEditObject();
      this.hForm.issuerId.setValue(this.creatingSignOff.issuerId);

      this.hForm.note.setValue(this.creatingSignOff.note);

      if (this.creatingSignOff.warehouseId != this.previousWarehouseId) {
          this._changeRef.detectChanges();
          this.previousWarehouseId = this.creatingSignOff.warehouseId;
          this.itemLookupComponent.fetchArticles(true);
      } else {
          this.itemLookupComponent.refreshItemLookup();
      }

      this.selectedArticle = null;
      this.headerSaved = true;
      this.disableForm();
  }

  async selectNext() {
      var index = this.signOffs.findIndex(x => x.id == this.creatingSignOff.id);

      if (index == 0) {
          if (this.page == 1)
              return;

          await this.prevpage();
          await this.fetchSignOffs();

          if (this.signOffs.length > 0) {
              this.selectSignOff(this.signOffs[this.signOffs.length - 1]);
          }
      }
      else {
          this.selectSignOff(this.signOffs[index - 1]);
      }
  }

  async selectPrevious() {
      var index = this.signOffs.findIndex(x => x.id == this.creatingSignOff.id);
      if (index == (this.signOffs.length - 1)) {
          if (this.page == this.maxPage)
              return;

          await this.nextPage();
          await this.fetchSignOffs();

          if (this.signOffs.length > 0) {
              this.selectSignOff(this.signOffs[0]);
          }
      }
      else {
          this.selectSignOff(this.signOffs[index + 1]);
      }
  }

  startEditing(header: boolean) {
      this.enableHeaderForm(false);
      this.disableItemForm();
      this.resetItemForm();
      this.startedEditing = true;
      if (this.editingItems)
          this.editingItems = false;
      if (header) {
        this.headerSaved = false;
        this._changeRef.detectChanges();
        this.firstHeaderFormElement.nativeElement.focus();
      }
  }
  startAddingItems() {
      if (this.itemLookupComponent.articles.length == 0)
          this.itemLookupComponent.fetchArticles();
      this.enableItemForm();
      this.startedEditing = true;
      this.editingItems = true;
      if(!this.onHeaderTab)
        this.itemCodeInput.nativeElement.focus();
  }

  cancelEditing(header: boolean = true) {
      this.selectSignOff(this.activeSignOffPreEdit, false);
      this.disableForm();
      if (!header) {
          this.resetItemForm();
      }
      this.startedEditing = false;
      this.editEnabled = true;
      this.addEnabled = false;
      this.editingItems = false;
      if (this.creatingSignOff.id != undefined)
          this.headerSaved = true;
  }

  cancelAdding() {
      this.disableForm();
      this.resetActiveSignOff();
      this.addEnabled=false;
      this.resetForm();
  }

  manualInputDate(id: string) {
      var pickerElement = $("#" + id);
      $(pickerElement).trigger("apply.daterangepicker", [pickerElement.data("daterangepicker")]);
  }

  selectOnFocus(id: string) {
      $("#" + id).select();
  }

  async createHeaderForm() {
      this.headerForm = this.formBuilder.group({
          issuerId: [0, Validators.required],
          warehouseId: [0, Validators.required],
          date: [],
          note: [''],
          signOffType:['_Standard_sign_off', Validators.required]
      });

      var self = this;

      $("#add_signOff_warehouseId").select2();
      $('#add_signOff_warehouseId').on("change", function () {
          self.selectAddSignOffWarehouseId.nativeElement.value = $(this).val();
          self.addWarehouseId = $(this).val();
      });

      $("#add_signOff_currencyId").select2();
      $('#add_signOff_currencyId').on("change", function () {
          self.selectAddSignOffCurrencyId.nativeElement.value = $(this).val();
          self.addCurrencyId = $(this).val();
      });

      $("#add_signOff_type").select2();
      $('#add_signOff_type').on("change", function () {
          self.selectAddSignOffType.nativeElement.value = $(this).val();
          self.addType = $(this).val();
      });

      var start = (new Date()).setDate((new Date()).getDate() - 29);
      var end = new Date();
      $("#kt_bill_date_add_picker").daterangepicker({
          singleDatePicker: true,
          autoApply: true,
          startDate: start,
          endDate: end,
          locale: {
              format: 'DD/MM/YYYY'
          }
      });
      $("#kt_bill_date_add_picker").on('apply.daterangepicker', function (ev, picker) {
          self.date = picker.startDate._d;
          self.headerForm.controls.date.setValue(self.date);
      });
      var im2 = new Inputmask("99/99/9999");
      im2.mask($("#kt_bill_date_add_picker"));

      if (this.editEnabled && !this.startedEditing)
          this.disableForm();
  }

  createAddItemForm() {
      this.addItemForm = this.formBuilder.group({
          quantity: [null, [Validators.required, Validators.min(0.000001)]],
      });
  }

  disableForm() {
      this.disableHeaderForm(false);
      this.disableItemForm();
  }
  disableHeaderForm(emit: boolean = true) {
      if (emit == false) {
          this.headerForm.disable({ emitEvent: false });
      } else {
          this.headerForm.disable();
      }

      this.headerFormEnabled = false;
      $("#kt_bill_date_add_picker").prop("disabled", true);
  }
  disableItemForm() {
      this.addItemForm.disable();
      if (this.itemFormEnabled) {
          this.itemFormEnabled = false;
          setTimeout(() => {
              this.addItemsButton.nativeElement.focus();
          }, 0);
      }
      $("#itemCodeInput").prop("disabled", true);
      $("#itemLookup").addClass("disabled");
  }

  enableForm() {
      this.enableHeaderForm();
      this.enableItemForm();
  }
  enableHeaderForm(emit: boolean = true) {
      if (emit == false)
          this.headerForm.enable({ emitEvent: false });
      else
          this.headerForm.enable();
      this.headerFormEnabled = true;
      $("#kt_bill_date_add_picker").prop("disabled", false);
  }
  enableItemForm() {
      this.addItemForm.enable();
      this.itemFormEnabled = true;
      $("#itemCodeInput").prop("disabled", false);
      $("#itemLookup").removeClass("disabled");
  }

  async resetForm() {
    //   $('#add_signOff_currencyId').val(this.currencies[0].id).trigger("change");
      if(this.warehouses!=undefined && this.warehouses.length>0)
        $('#add_signOff_warehouseId').val(this.warehouses[0].id).trigger("change");
      if(this.signOffTypes!=undefined)
        $('#add_signOff_type').val(this.signOffTypes[0]).trigger("change");
      $("#kt_bill_date_add_picker").data('daterangepicker').setStartDate(new Date());
      this.date = new Date();
      this.hForm.date.setValue(this.date);
      this.headerForm.controls.note.setValue(null);

      $('#itemCodeInput').val(null);
      this.selectedArticle = undefined;
      this.addItemForm.controls.quantity.setValue(null);

      this.addEnabled = false;
      this.editEnabled = false;
      this.startedEditing = false;

      this.totalNC = 0;
      this.totalVPC = 0;
      this.totalMPC = 0;
      this.headerFormEnabled = false;
      this.itemFormEnabled = false;

      this.changedArrivalDate = false;
  }

  async addSignOff() {
      if (this.creationInProgress)
          return;
      this.creationInProgress = true;
      this.isSubmitted = false;
      if (this.hForm.invalid) {
          this.isSubmitted = true;
      }
      if (!this.validateAddForm()) {
          this.isSubmitted = true;
      }
      if (this.isSubmitted == true) {
          this.creationInProgress = false;
          return;
      }

      this.creatingSignOff.issuerId=this.data.user.id;
      this.creatingSignOff.issuerName = this.data.user.username;
      try {
          await this.signOffRest.createSignOff(this.creatingSignOff);
          this.creatingSignOff.id = await this.getNewlyCreatedId();
          this.activeSignOffPreEdit = this.makePreEditObject();
          this.headerSaved = true;
          this.editingItems = true;
          this.setPostAddingLayout();

          if (this.creatingSignOff.warehouseId != this.previousWarehouseId) {
              this.previousWarehouseId = this.creatingSignOff.warehouseId;
              this.itemLookupComponent.fetchArticles(true);
          } else {
              this.itemLookupComponent.refreshItemLookup();
          }

          toastr.options = this.data.toastrOptions;
          toastr.success(this.translate.instant('_Added_signOff_text'), this.translate.instant("_Success"));
          this.fetchSignOffs();
      }
      catch (err) {
        
          toastr.options = this.data.toastrOptions;
          toastr.error(err.error['Message'], this.translate.instant("_Error"));
      }
      this.creationInProgress = false;
  }
  makePreEditObject() {
      var obj = new SignOffModel();
      Object.keys(this.creatingSignOff).forEach(key => {
          if (key != 'articles') {
              let value = this.creatingSignOff[key];
              obj[key] = value;
          }
      })
      obj.articles = [];
      this.creatingSignOff.articles.forEach(x => {
          var itemCopy = new SignOffArticleModel();
          Object.keys(x).forEach(key => {
              let value = x[key];
              itemCopy[key] = value;
          })
          obj.articles.push(itemCopy);
      });
      return obj;
  }
  async getNewlyCreatedId() {
      var response = await this.signOffRest.getSignOffs('', this.filterWarehouseId, this.datePipe.transform((new Date()), 'yyyy-MM-dd'), this.datePipe.transform((new Date()), 'yyyy-MM-dd'), this.data.user.clientId, 1, 1)
      var id = response.data[0].id;
      return id;
  }

  async editSignOff(instantReverse: boolean = false, causedByArticleId:any=null, itemQuantity:any=null) {
      this.isSubmitted = false;
      if (this.hForm.invalid) {
          this.isSubmitted = true;
      }
      if (!this.validateAddForm()) {
          this.isSubmitted = true;
      }
      if (this.isSubmitted == true) {
          return;
      }

      try {
          await this.signOffRest.editSignOff(this.creatingSignOff);
          this.activeSignOffPreEdit = this.makePreEditObject();
          this.startedEditing = false;
          this.editingItems = false;
          this.headerSaved = true;
          this.resetItemForm();
          this.disableForm();

          if (this.creatingSignOff.warehouseId != this.previousWarehouseId) {
              this.previousWarehouseId = this.creatingSignOff.warehouseId;
              this.itemLookupComponent.fetchArticles(true);
          }else{
              if(causedByArticleId!=null){
                  if(this.itemLookupComponent.articles.find(x=>x.id==causedByArticleId)!=undefined)
                      this.itemLookupComponent.updateWarehouseState(causedByArticleId, itemQuantity);
              }
          }

          toastr.options = this.data.toastrOptions;
          toastr.success(this.translate.instant('_Edited_signOff_text'), this.translate.instant("_Success"));
          this.fetchSignOffs();
      }
      catch (err) {
          if (instantReverse)
              this.creatingSignOff = this.activeSignOffPreEdit;

          toastr.options = this.data.toastrOptions;
          toastr.error(err.error['Message'], this.translate.instant("_Error"));
      }
  }

  async deleteSignOff(id: string) {
      var self = this;
      var question_text = this.translate.instant("_Delete_signOff_text");
      var yes_text = this.translate.instant("_Yes_text");
      var no_text = this.translate.instant("_No_text");
      Swal.fire({
          html: question_text,
          icon: "warning",
          buttonsStyling: true,
          showCancelButton: true,
          confirmButtonText: yes_text,
          cancelButtonText: no_text,
          customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: 'btn btn-secondary'
          }
      }).then(async (result) => {
          if (result.isConfirmed) {
              await this.signOffRest.deleteSignOff(id);
              if (this.creatingSignOff!=undefined && this.creatingSignOff.id == id) {
                  this.resetActiveSignOff();
                  this.disableForm();
                  this.startedEditing = false;
                  this.editEnabled = true;
                  this.addEnabled = false;
                  this.editingItems = false;
                  this.resetForm();
              }
              await this.fetchSignOffs();
              toastr.options = this.data.toastrOptions;
              toastr.success(this.translate.instant('_Deleted_signOff_text'), this.translate.instant("_Success"));
          } else if (result.dismiss === Swal.DismissReason.cancel) {
          }
      });
  }

  shouldFetchArticleState(id: number) {
      if (this.itemLookupComponent.articles.find(x => x.id == id) != undefined)
          return false;
      if (this.documentArticles != undefined && this.documentArticles.find(x => x.id == id) != undefined)
          return false;

      return true;
  }
  async selectItem(item: SignOffArticleModel) {
      if (this.shouldFetchArticleState(item.id)) {
          try {
              var articlesToFetch = await this.articleRestService.getArticlesWithStates(this.data.user.clientId, this.creatingSignOff.warehouseId, 1, 99999, '', false, item.id, null, true);
              if (this.documentArticles == undefined)
                  this.documentArticles = [];
              this.documentArticles.push(articlesToFetch[0]);
              this.selectedArticle = articlesToFetch[0];
          } catch (err: any) {
              toastr.options = this.data.toastrOptions;
              toastr.error(err.error['Message'], this.translate.instant("_Error"));
          }
      } else {
          if (this.documentArticles != undefined && this.documentArticles.find(x => x.id == item.id))
              this.selectedArticle = this.documentArticles.find(x => x.id == item.id);
          else
              this.selectedArticle = this.itemLookupComponent.articles.find(x => x.id == item.id);
      }

      this.enableItemForm();
      $("#quantity").val(item.quantity);
      this.iForm.quantity.setValue(item.quantity);
     

      this.startedEditing = true;
      this.editingItemIndex = this.creatingSignOff.articles.indexOf(item);
      this.editingItems = true;
      this.editingItem = item;

      this.itemsTrigger.nativeElement.click();
  }
  editItem() {
      this.isSubmitted = false;
      if (this.addItemForm.invalid) {
          this.isSubmitted = true;
      }
      if (!this.validateItemForm()) {
          this.isSubmitted = true;
      }
      if (this.isSubmitted) {
          return;
      }

      var itemId = 0; var itemQuantity = 0;var quantityDiff=0;
      
          var newQuantity = parseFloat(this.iForm.quantity.value.toFixed(6));
          quantityDiff = this.editingItem.quantity - newQuantity;
          this.editingItem.quantity = newQuantity;
          this.editingItem.nc = this.editingItem.nc;
          this.editingItem.vpc = this.editingItem.vpc;
          this.editingItem.mpc = this.editingItem.mpc;
          this.editingItem.totalNC = parseFloat((this.editingItem.nc * this.editingItem.quantity).toFixed(6));
          this.editingItem.totalVPC = parseFloat((this.editingItem.vpc * this.editingItem.quantity).toFixed(6));
          this.editingItem.totalMPC = parseFloat((this.editingItem.mpc * this.editingItem.quantity).toFixed(6));
          itemId = this.editingItem.id;

      this.creatingSignOff.totalNC = 0;
      this.creatingSignOff.articles.forEach(x => this.creatingSignOff.totalNC += x.totalNC);
      this.creatingSignOff.totalMPC = 0;
      this.creatingSignOff.articles.forEach(x => this.creatingSignOff.totalMPC += x.totalMPC);
      this.creatingSignOff.totalVPC=0;
      this.creatingSignOff.articles.forEach(x => this.creatingSignOff.totalVPC += x.totalVPC);

      this.editSignOff(false, itemId, quantityDiff);
      this.editingItem = undefined;
      this.resetItemForm();
  }

  validateAddForm() {
      var check = true;

      if (this.addWarehouseId == 0) {
          $("#add_signOff_warehouseId").nextAll('span').first().addClass("is-invalid");
          check = false;
      }
      else {
          $("#add_signOff_warehouseId").nextAll('span').first().removeClass("is-invalid");
      }

      if (this.addType == '') {
        $("#add_signOff_type").nextAll('span').first().addClass("is-invalid");
        check = false;
    }
    else {
        $("#add_signOff_type").nextAll('span').first().removeClass("is-invalid");
    }

      // if (this.addCurrencyId == 0) {
      //     $("#add_signOff_currencyId").nextAll('span').first().addClass("is-invalid");
      //     check = false;
      // }
      // else {
      //     $("#add_signOff_currencyId").nextAll('span').first().removeClass("is-invalid");
      // }

      return check;
  }

  proceedToAddItems() {
      this.isSubmitted = false;
      if (this.headerForm.invalid) {
          this.isSubmitted = true;
      }
      if (!this.validateAddForm()) {
          this.isSubmitted = true;
      }
      if (this.isSubmitted == true) {
          return;
      }

      $("#addSignOffItemsTrigger").click();
  }

  proceedToEditItems() {
      this.isSubmitted = false;
      if (this.headerForm.invalid) {
          this.isSubmitted = true;
      }
      if (!this.validateAddForm()) {
          this.isSubmitted = true;
      }
      if (this.isSubmitted == true) {
          return;
      }

      $("#addSignOffItemsTrigger").click();
  }

  backToHeader() {
      $("#headerModalTrigger").click();
  }

  negateEnter(event: KeyboardEvent) {
      if (event.key == "Enter")
          event.preventDefault();
  }

  showList() {
      this.listTrigger.nativeElement.click();
  }

  editNumber() {
      if (!this.canEditNumber())
          return;

      this.signOffNumberOpen.nativeElement.click();
      var input = $("#signOffNumberInput");
      input.removeClass("is-invalid");

      if (this.creatingSignOff.documentNumber != null)
          input.val(this.creatingSignOff.documentNumber);
      else
          input.val(this.nextNumber);
  }
  canEditNumber() {
      if (this.editEnabled && !this.startedEditing)
          return false;

      return true;
  }
  saveNumber() {
      var input = $("#signOffNumberInput");
      if (input.val() == '' || input.val().includes('.')) {
          input.addClass("is-invalid");
          return;
      } else {
          input.removeClass("is-invalid");
          this.creatingSignOff.documentNumber = input.val();
          this.creatingSignOff.customDocumentNumber = this.creatingSignOff.documentNumber;
          this.creatingSignOff.signOffNumber = this.creatingSignOff.documentNumber + "/" + ((new Date()).getFullYear() % 100);
      }

      this.signOffNumberClose.nativeElement.click();
  }

  switchToHeaderAndEnableAdding() {
      this.headerTrigger.nativeElement.click();
      this.enableAdding();
  }

  enableAdding() {
      this.resetActiveSignOff();
      this.getNextNumber();
      this.resetForm();
      this.addEnabled = true;
      this.headerSaved=false;
      this.enableHeaderForm();
      this._changeRef.detectChanges();
      this.firstHeaderFormElement.nativeElement.focus();
  }
  setPostAddingLayout() {
      this.addEnabled = false;
      this.editEnabled = true;
      this.startedEditing = true;
      this.disableHeaderForm(false);
      this.enableItemForm();
      this.itemsTrigger.nativeElement.click();
  }

  checkForSamePriceItem(activeItem: ArticleModel = null, updating: boolean = false) {
    if (updating) {
        return this.creatingSignOff.articles.find(x => x.id == this.editingItem.id && x.mpc == activeItem.mpc && this.creatingSignOff.articles.indexOf(x) != this.editingItemIndex);
    } else {
        return this.creatingSignOff.articles.find(x => x.id == activeItem.id && x.mpc == activeItem.mpc);
    }
  }

  async addItem(save: boolean = false) {
      this.isSubmitted = false;
      if (this.addItemForm.invalid) {
          this.isSubmitted = true;
      }
      if (!this.validateItemForm()) {
          this.isSubmitted = true;
      }
      if (this.isSubmitted) {
          return;
      }

      var itemId = 0; var itemQuantity = 0;

      var existingPriceArticle = this.checkForSamePriceItem(this.selectedArticle);
      if (existingPriceArticle != null) {
            this.creatingSignOff.totalNC -= parseFloat((existingPriceArticle.totalNC).toFixed(6));
            this.creatingSignOff.totalVPC -= parseFloat((existingPriceArticle.totalVPC).toFixed(6));
            this.creatingSignOff.totalMPC -= parseFloat((existingPriceArticle.totalMPC).toFixed(6));

            existingPriceArticle.quantity += parseFloat((this.iForm.quantity.value).toFixed(6));
            itemId = existingPriceArticle.id;
            itemQuantity = parseFloat(this.iForm.quantity.value.toFixed(6));
            existingPriceArticle.totalNC = parseFloat((existingPriceArticle.nc * existingPriceArticle.quantity).toFixed(6));
            existingPriceArticle.totalVPC = parseFloat((existingPriceArticle.vpc * existingPriceArticle.quantity).toFixed(6));
            existingPriceArticle.totalMPC = parseFloat((existingPriceArticle.mpc * existingPriceArticle.quantity).toFixed(6));
            
            this.creatingSignOff.totalNC += parseFloat((existingPriceArticle.totalNC).toFixed(6));
            this.creatingSignOff.totalVPC += parseFloat((existingPriceArticle.totalVPC).toFixed(6));
            this.creatingSignOff.totalMPC += parseFloat((existingPriceArticle.totalMPC).toFixed(6));
      }
      else {
        var item = new SignOffArticleModel();
        item.id = this.selectedArticle.id;
        item.code = this.selectedArticle.code;
        item.name = this.selectedArticle.name;
        item.quantity = parseFloat((this.iForm.quantity.value).toFixed(6));
        item.nc = this.selectedArticle.nc;
        item.vpc = this.selectedArticle.vpc;
        item.mpc = this.selectedArticle.mpc;
        item.totalNC = parseFloat((item.nc * item.quantity).toFixed(6));
        item.totalVPC = parseFloat((item.vpc * item.quantity).toFixed(6));
        item.totalMPC = parseFloat((item.mpc * item.quantity).toFixed(6));
        item.measureShort = this.selectedArticle.measureShort;
        item.g = this.selectedArticle.groupId;
        item.gt = this.selectedArticle.groupTypeId;
        this.creatingSignOff.articles.push(item);
        this.creatingSignOff.totalNC += parseFloat((item.totalNC).toFixed(6));
        this.creatingSignOff.totalVPC += parseFloat((item.totalVPC).toFixed(6));
        this.creatingSignOff.totalMPC += parseFloat((item.totalMPC).toFixed(6));

        itemId = item.id;
        itemQuantity = item.quantity;
        item.uniqueItemId = uuidv4();
    }

      this.selectedArticle = undefined;
      this.resetItemForm();

      await this.editSignOff(true, itemId, -itemQuantity);
  }

  resetItemForm() {
      $("#itemCodeInput").val(undefined);
      this.iForm.quantity.setValue(null);
     
      this.selectedArticle = undefined;
      this.editingItem = undefined;
      $("#itemCodeInput").removeClass('is-invalid');
      this.isSubmitted=false;
  }

  async removeItem(item: SignOffArticleModel) {
      this.creatingSignOff.totalNC -= item.totalNC;
      this.creatingSignOff.totalVPC -= item.totalVPC;
      this.creatingSignOff.totalMPC -= item.totalMPC;

      this.creatingSignOff.articles.forEach((element, index) => {
          if (element == item) this.creatingSignOff.articles.splice(index, 1);
      });

      await this.editSignOff(false, item.id, item.quantity);
  }

  validateItemForm() {
      var check = true;

      if (this.selectedArticle == undefined) {
          check = false;
          $("#itemCodeInput").addClass('is-invalid');
      }
      else {
          $("#itemCodeInput").removeClass('is-invalid');
      }

      return check;
  }

  async selectArticle(a: ArticleModel) {
      $("#itemCodeInput").val(a.code);
      this.selectedArticle = a;

      if (this.selectedArticle != undefined) {
          $("#itemCodeInput").removeClass('is-invalid');
      }
  }
  selectArticleByCode(event: KeyboardEvent, c: string) {
      if (event.key != 'Enter')
          return;

      event.preventDefault();
      this.selectedArticle = this.itemLookupComponent.articles.find(x => x.code == c);

      if (this.selectedArticle != undefined) {
          $("#itemCodeInput").removeClass('is-invalid');
      }
  }
  enterOnRow(event: KeyboardEvent) {
      if (event.key == 'Enter') {
          event.preventDefault();
          var element = event.target as HTMLElement;
          element.click();
      }
  }

  //pagination
  initPages() {
    this.pages = [];
    for (var i = this.firstPage; i <= this.lastPage; i++) {
        this.pages.push(i);
    }
}

nextPage() {
    if ($("#kt_customers_table_next").hasClass("disabled"))
        return;

    if (this.page == 1 && this.firstPage == 1) {
        this.page++;
    }

    else {
        this.page++;
        if (this.counter == 1) {
            if (this.maxPage > (this.lastPage + this.offset)) {
                this.firstPage++;
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }

            else if (this.maxPage > (this.firstPage + this.offset)) {
                this.firstPage++;
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }

            else if (this.maxPage) {
                if (this.maxPage - this.offset > 0)
                    this.firstPage = this.maxPage - this.offset;
                else
                    this.firstPage = 1;
                this.lastPage = this.maxPage;
            }
            this.counter = 0;
        }
        else {
            if (this.maxPage > (this.lastPage + this.offset)) {
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }

            else if (this.maxPage > (this.firstPage + this.offset)) {
                this.lastPage = this.firstPage + this.offset;
                this.initPages();
            }

            else {
                if (this.maxPage - this.offset > 0)
                    this.firstPage = this.maxPage - this.offset;
                else
                    this.firstPage = 1;
                this.lastPage = this.maxPage;
            }
        }
        this.counter++;
    }
    this.fetchSignOffs();
    this.enablePrevious();
    this._changeRef.detectChanges();
}

prevpage() {
    if ($("#kt_customers_table_previous").hasClass("disabled"))
        return;

    this.page--;
    if (this.counter == 1) {
        if (this.maxPage > this.lastPage + this.offset) {
            if (this.page == 2)
                this.firstPage = 1;
            else if (this.page != 1 && (this.firstPage - 1) > 1)
                this.firstPage--;
            else
                this.firstPage = 1;
            if (this.maxPage > this.firstPage + this.offset)
                this.lastPage = this.firstPage + this.offset;
            else
                this.lastPage = this.maxPage;
            this.initPages();
        }
        else {
            if (this.page == 2)
                this.firstPage = 1;
            else if (this.page != 1 && (this.firstPage - 1) > 1)
                this.firstPage--;
            else
                this.firstPage = 1;
            if (this.maxPage > this.firstPage + this.offset)
                this.lastPage = this.firstPage + this.offset;
            else
                this.lastPage = this.maxPage;
            this.initPages();
        }
        this.counter = 0;
    }
    else {
        if (this.maxPage > this.lastPage + this.offset) {
            this.lastPage = this.firstPage + this.offset;
            this.initPages();
        }
        else {
            if (this.page == 2)
                this.firstPage = 1;
            else if (this.page != 1 && (this.firstPage - 1) > 1)
                this.firstPage--;
            else
                this.firstPage = 1;
            if (this.maxPage > this.firstPage + this.offset)
                this.lastPage = this.firstPage + this.offset;
            else
                this.lastPage = this.maxPage;
            this.initPages();
        }
    }
    this.counter++;
    this.fetchSignOffs();
    this.enablePrevious();
    this._changeRef.detectChanges();

}

goToPage(p: number) {
    this.page = p;
    this.fetchSignOffs();
    this.enablePrevious();
    this._changeRef.detectChanges();
}

morePages() {
    if ($("#kt_customers_table_morePages").hasClass("disabled"))
        return;
    if (this.maxPage > this.lastPage + this.offset) {
        this.firstPage = this.lastPage;
        this.lastPage = this.firstPage + this.offset;
    }
    else {
        this.firstPage = this.maxPage - this.offset;
        this.lastPage = this.maxPage;
    }
    this.page = this.firstPage;
    this.fetchSignOffs();
    this.initPages();
    this.enablePrevious();
    this._changeRef.detectChanges();
}


perPageHandler() {
    this.firstLoad = true;
    this.page = 1;
    this.fetchSignOffs();
    this.enablePrevious();
    this._changeRef.detectChanges();
}

enablePrevious() {
    if (this.page > 1) {
        $(document).ready(function () {
            $("#kt_customers_table_previous").removeClass("disabled");
        })
    }
    else if (this.page == 1) {
        $(document).ready(function () {
            $("#kt_customers_table_previous").addClass("disabled");
        })
    }

    if (this.page > 1 && this.page != this.maxPage) {
        $(document).ready(function () {
            $("#kt_customers_table_next").removeClass("disabled");
        })
    }

    else {
        $(document).ready(function () {
            $("#kt_customers_table_next").addClass("disabled");
        })
    }

    if (this.maxPage == 0) {
        $(document).ready(function () {
            $("#kt_customers_table_next").addClass("disabled");
            $("#morePages").addClass("disabled");
            $("#kt_customers_table_previous").addClass("disabled");
        })
    }

    if (this.page == this.maxPage) {
        $(document).ready(function () {
            $("#kt_customers_table_next").addClass("disabled");
        })
    }

    else {
        $(document).ready(function () {
            $("#kt_customers_table_next").removeClass("disabled");
        })
    }

    if (this.maxPage <= 5 || this.lastPage == this.maxPage) {
        $(document).ready(function () {
            $("#kt_customers_table_morePages").addClass("disabled");
        })
    }

    else {
        $(document).ready(function () {
            $("#kt_customers_table_morePages").removeClass("disabled");
        })
    }
}
}